html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: black;
  background: white;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
a:link, a:visited, a:active {
  outline: none;
  text-decoration: none;
}
ol, ul {
  list-style: none;
}
:focus {
  outline: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption, th, td {
  text-align: left;
  font-weight: normal;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}
blockquote, q {
  quotes: "" "";
}
html { box-sizing: border-box; }
*, *::before, *::after { box-sizing: inherit; }