
/*------------------------------------------- main_banner -------------------------------------------*/
.main_banner {
  -webkit-background-size: cover;
  background-size: cover;
  padding: 100px 0;
  &.developments {
    background-image: url(../img/developments-main.png);
    padding-top: 235px;
    padding-bottom: 150px;
    text-align: center;
  }
  &.main {
    background-image: url(../img/main_bg.jpg);
    margin-top: 135px;
    text-align: center;
    h1 {
      font-size: 90px;
      line-height: 97px;
    }
    h4 {
      margin-top: 50px;
      color: #fff;
    }
  }
  &.contacts {
    background-image: url(../img/contact-bg.jpg);
    padding-bottom: 550px;
    .divider_container {
      .text {
        h2 {
          color: #fff;
        }
        h3 {
          color: #fff;
        }
      }
    }
  }
  &.restaurant {
    background-image: url(../img/restaurant_bg.jpg);
    margin-top: 135px;
    text-align: center;
    h1 {
      font-size: 100px;
      line-height: 100px;
      span {
        font-size: 138px;
      }
    }
    .select {
      display: inline-block;
      margin-top: 125px;
      .text_menu {
        background: #fff;
        border: 2px solid #fff;
        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 6px;
          left: 20px;
          width: 18px;
          height: 26px;
          background-image: url(../img/place.png);
          -webkit-background-size: cover;
          background-size: cover;
        }
      }
    }
  }
  &.review {
    background-image: url(../img/review-bg.jpg);
    padding-top: 235px;
    padding-bottom: 150px;
    text-align: center;
  }
  &.franchising {
    background-image: url(../img/franchising-bg.jpg);
    padding-bottom: 500px;
    .divider_container {
      .text {
        h2 {
          color: #fff;
        }
        h3 {
          color: #fff;
        }
      }
    }
  }
  &.agreement {
    background-image: url(../img/agreement-bg.jpg);
    padding-top: 235px;
    padding-bottom: 150px;
    text-align: center;
  }
  &.registration {
    background-image: url(../img/registration-bg.jpg);
    padding-top: 235px;
    padding-bottom: 150px;
    text-align: center;
  }
  &.password {
    background-image: url(../img/password-bg.jpg);
    padding-top: 235px;
    padding-bottom: 150px;
    text-align: center;
  }
  &.one_restaurant {
    display: flex;
    justify-content: space-between;
    height: 800px;
    padding: 0;
    .left_bg {
      width: 50%;
      height: 100%;
      background-image: url(../img/rest-1.jpg);
      -webkit-background-size: cover;
      background-size: cover;
    }
    .right_bg {
      width: 50%;
      height: 100%;
      background-image: url(../img/rest-2.jpg);
      background-size: cover;
      display: flex;
      align-items: center;
      .white_bg {
        margin-top: 100px;
        background: #fff;
        padding: 75px 50px;
        display: inline-block;
        margin-left: 125px;
        text-align: center;
        width: 450px;
        position: relative;
        &:before {
          content:"";
          display: block;
          border: 20px solid #fff;
          position: absolute;
          top: -30px;
          bottom: -30px;
          left: -30px;
          right: -30px;
        }
        .place {
          position: relative;
          padding-left: 30px;
          font-weight: bold;
          span {
            display: block;
            color: #6e6e6e;
            font-size: 15px;
          }
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 17px;
            height: 23px;
            background-image: url(../img/place.png);
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
        .metro {
          position: relative;
          padding-left: 30px;
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 24px;
            height: 18px;
            background-image: url(../img/metro.png);
            background-size: cover;
          }
        }
        .tel {
          position: relative;
          padding-left: 30px;
          margin-top: 50px;
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 18px;
            height: 18px;
            background-image: url(../img/tel.png);
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
        .date {
          position: relative;
          padding-left: 30px;
          margin-top: 50px;
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 23px;
            height: 23px;
            background-image: url(../img/date.png);
            -webkit-background-size: cover;
            background-size: cover;
          }
        }
      }
    }
  }
  &.personal_area {
    background-image: url(../img/personal.jpg);
    padding-top: 235px;
    padding-bottom: 150px;
    .login {
      width: 500px;
      background: #fff;
      padding: 70px;
      text-align: center;
      h4 {
        text-transform: uppercase;
      }
      input {
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0;
        margin-top: 20px;
        padding: 0 10px;
      }
      .btn_3 {
         width: 100%;
         margin-top: 40px;
       }
      .btn_4 {
        width: 100%;
        margin-top: 20px;
      }
      .forgot {
        display: inline-block;
        margin-top: 40px;
        color: #000;
        text-decoration: underline;
      }
    }
  }
}

/*------------------------------------------- divider_container -------------------------------------------*/
.divider_container {
  margin: 100px 0;
  text-align: center;
  &.main_divider {

  }
  &.no_icons {
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .text {
    text-align: center;
    margin: 0 30px;
    img {
      margin-top: 30px;
    }
  }
}

/*------------------------------------------- select -------------------------------------------*/
.select {
  position: relative;
  .text_menu {
    width: 300px;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 10px 40px 10px 30px;
    cursor: pointer;
    position: relative;
    &.open {
      &:after {
        content: "";
        top: 16px;
        right: 27px;
        transform: rotate(-225deg);
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      right: 27px;
      height: 13px;
      width: 13px;
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(-45deg);
      transition: all 0.3s;
    }
  }
  .drop_menu {
    position: absolute;
    top: 45px;
    left: 18px;
    right: 18px;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    display: none;
    z-index: 10;
    background: #fff;
    li {
      padding: 10px 10px 10px 13px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #bc916c;
        color: #fff;
      }
    }
  }
}




/*------------------------------------------- filter_container -------------------------------------------*/
.filter_container {
  .text_block {
    text-align: center;
    margin: 100px 0 50px 0;
  }
  .address_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      text-transform: uppercase;
      margin-right: 60px;
    }
    .date {
      color: #cc936b;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
  .calendar_block {
    margin: 60px 0;
    border-bottom: 2px solid #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 27px;
      left: 45px;
      height: 13px;
      width: 13px;
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(45deg);
      cursor: pointer;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 27px;
      right: 45px;
      height: 13px;
      width: 13px;
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(-135deg);
      cursor: pointer;
    }
    li {
      text-align: center;
      cursor: pointer;
      &.active {
        color: #cc936b;
      }
      .week {
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
      }
      .day {
        font-weight: bold;
        display: block;
        margin-bottom: 20px;
      }
    }
  }
  .filter_mobile {
    display: none;
    width: 300px;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 10px 40px 10px 30px;
    cursor: pointer;
    position: relative;
    margin: 0 auto;
    &.open {
      &:after {
        content: "";
        top: 16px;
        right: 27px;
        transform: rotate(-225deg);
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      right: 27px;
      height: 13px;
      width: 13px;
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(-45deg);
      transition: all 0.3s;
    }
  }
  .filter_btn_block {
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 0 20px 20px 0;
      border: 2px solid #000;
      border-radius: 30px;
      padding: 10px 30px;
      cursor: pointer;
      transition: all 0.3s;
      &.active {
        border: 2px solid #cc936b;
        background: #cc936b;
        color: #fff;
      }
    }
  }
}

/*------------------------------------------- developments_container -------------------------------------------*/
.developments_container {
  .developments_item {
    margin-top: 60px;
    .img_container {
      height: 360px;
      overflow: hidden;
      img {
        max-width: none;
        height: 100%;
      }
    }
    .caption {
      font-weight: bold;
      font-size: 20px;
      margin-top: 25px;
      color: #000;
    }
    .place {
      font-family: 'Athelas';
      margin-top: 15px;
      position: relative;
      padding-left: 30px;
      color: #000;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 17px;
        height: 23px;
        background-image: url(../img/place.png);
        -webkit-background-size: cover;
        background-size: cover;
      }
    }
    .time {
      color: #000;
      font-family: 'Athelas';
      margin-top: 15px;
      position: relative;
      padding-left: 30px;
      &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        display: inline-block;
        width: 19px;
        height: 19px;
        background-image: url(../img/time.png);
        -webkit-background-size: cover;
        background-size: cover;
      }
    }
  }
}

/*------------------------------------------- more_btn -------------------------------------------*/
.more_btn {
  text-align: center;
  margin: 100px 0;
  .more {
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    background: #cc936b;
    padding: 30px 50px;
    border-radius: 40px;
    border: 1px solid #bc916c;
    transition: all 0.3s;
    &:hover {
      background: transparent;
      color: #bc916c;
    }
  }
}

/*------------------------------------------- up_btn -------------------------------------------*/
.up_btn {
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 70px;
  height: 70px;
  background: #bc916c;
  border-radius: 50%;
  z-index: 100;
  &:before {
    content: "";
    position: absolute;
    top: 26px;
    left: 20px;
    width: 30px;
    height: 30px;
    border-left: 4px solid #fff;
    border-top: 4px solid #fff;
    transform: rotate(45deg);
}
}

/*------------------------------------------- menu_container -------------------------------------------*/
.main_menu_container {
  .menu_item {
    text-align: center;
    .img_block {
      height: 240px;
      display: block;
    }
    h4 {
      color: #000;
      margin-top: 40px;
      font-family: 'Athelas';
    }
    a {
      font-size: 23px;
      line-height: 23px;
      text-transform: uppercase;
      color: #000;
    }
  }
}

/*------------------------------------------- stock_1 -------------------------------------------*/
.stock_1 {
  height: 570px;
  display: flex;
  align-items: flex-end;
  position: relative;
  .bg_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .dark_bg {
    text-align: center;
    background: rgba(0,0,0,0.7);
    padding: 30px;
    position: relative;
    h4 {
      color: #fff;
    }
    p {
      color: #fff;
      margin-top: 30px;
    }
  }
}

/*------------------------------------------- stock_2 -------------------------------------------*/
.stock_2 {
  margin-top: 100px;
  position: relative;
  img {
    width: 100%;
  }
  .stock_2_container {
    text-align: center;
    position: absolute;
    top: 70px;
    right: 70px;
    left: 70px;
    bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .divider_container {
      margin: 0 0 50px 0;
      .text {
        color: #fff;
      }
    }
    h4 {
      color: #fff;
      font-family: 'Athelas';
    }
    .btn_1 {
      margin-top: auto;
    }
  }
}

/*------------------------------------------- photo_container -------------------------------------------*/
.photo_container {
  .photo_item {
    margin-bottom: 30px;
    height: 360px;
    padding: 10px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: -10px;
      left: 0;
      border: 2px solid #bd926c;
      display: block;
    }
  }
  .btn_container {
    margin-top: 40px;
    text-align: center;
  }
}

/*------------------------------------------- video_container -------------------------------------------*/
.video_container {
  margin-bottom: 200px;
  .video_item {
    margin-bottom: 30px;
    height: 360px;
    position: relative;
    padding: 10px;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: -10px;
      left: 0;
      border: 2px solid #bd926c;
      display: block;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      height: 70px;
      width: 70px;
      background-image: url(../img/youtube.png);
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
  .btn_container {
    margin-top: 40px;
    text-align: center;
  }
}

/*------------------------------------------- report_container -------------------------------------------*/
.report_container {
  &.video {
    .report_item {
      .img_block {
        overflow: hidden;
        height: 335px;
        display: block;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          height: 70px;
          width: 70px;
          background-image: url(../img/youtube.png);
          -webkit-background-size: cover;
          background-size: cover;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .report_item {
    text-align: center;
    margin-bottom: 80px;
    .img_block {
      overflow: hidden;
      height: 235px;
      display: block;
    }
    h4 {
      margin-top: 20px;
      color: #000;
    }
    p {
      margin-top: 20px;
      color: #000;
    }
  }
}

/*------------------------------------------- pagination -------------------------------------------*/
.pagination {
  margin-bottom: 200px;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #000;
      font-size: 36px;
      display: inline-block;
      height: 60px;
      width: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 50%;
      &.active {
        color: #fff;
        background: #bc916c;
      }
    }
  }
}

/*------------------------------------------- description_block -------------------------------------------*/
.description_block {
  text-align: center;
  position: relative;
  z-index: 10;
  h4 {
    color: #bc916c;
  }
  p {
    margin: 50px 0;
  }
}

/*------------------------------------------- gallery -------------------------------------------*/
.gallery {
  margin-bottom: 200px;
  .gallery_item {
    margin-bottom: 15px;
    overflow: hidden;
  }
}

/*------------------------------------------- one_video -------------------------------------------*/
.one_video {
  margin-bottom: 50px;
  position: relative;
  z-index: 10;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 100%;
    background: #e8e8e8;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  iframe {
    width: 100%;
    height: 685px;
  }
}

/*------------------------------------------- description_block_2 -------------------------------------------*/
.description_block_2 {
  text-align: center;
  margin-bottom: 200px;
  position: relative;
  z-index: 10;
  p {
    margin-top: 20px;
  }
}

/*------------------------------------------- one_event -------------------------------------------*/
.one_event {
  position: relative;
  overflow: hidden;
  margin-top: 135px;
  padding-bottom: 200px;
  .left_arrow {
    position: absolute;
    top: 40%;
    left: -100px;
    width: 70px;
    height: 70px;
    background: #bc916c;
    border-radius: 50%;
    z-index: 11;
    &:before {
      content: "";
      position: absolute;
      top: 19px;
      left: 27px;
      width: 30px;
      height: 30px;
      border-left: 4px solid #fff;
      border-top: 4px solid #fff;
      transform: rotate(-45deg);
    }
  }
  .right_arrow {
    position: absolute;
    top: 40%;
    right: -100px;
    width: 70px;
    height: 70px;
    background: #bc916c;
    border-radius: 50%;
    z-index: 10;
    &:before {
      content: "";
      position: absolute;
      top: 19px;
      left: 16px;
      width: 30px;
      height: 30px;
      border-left: 4px solid #fff;
      border-top: 4px solid #fff;
      transform: rotate(135deg);
    }
  }
  .container {
    position: relative;
  }
  .img_block {
    position: relative;
    z-index: 10;
    height: 600px;
    overflow: hidden;
    margin-bottom: 50px;
  }
  &:before {
    content:"";
    position: absolute;
    top: 360px;
    left: 0;
    right: 0;
    height: 100%;
    background: #e8e8e8;
  }
}

/*------------------------------------------- about_description -------------------------------------------*/
.about_description {
  margin-top: 240px;
  text-align: center;
  h4 {
    font-family: 'Athelas';
    margin-top: 10px;
  }
}

/*------------------------------------------- number_block -------------------------------------------*/
.number_block {
  background-image: url(../img/number-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  padding: 70px 0;
  margin-top: 100px;
  .white_bg {
    background: #fff;
    padding: 60px;
    .divider {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        text-align: center;
        margin: 0 30px;
        h2 {
          font-weight: bold;
        }
        img {
          margin-top: 30px;
        }
      }
    }
    .numbers {
      display: flex;
      justify-content: space-between;
      .number_item {
        margin-top: 100px;
        width: 17%;
        text-align: center;
        .num {
          font-size: 110px;
          font-family: 'Abril Fatface';
          line-height: 110px;
          font-weight: bold;
        }
        h4 {
          font-size: 45px;
          line-height: 45px;
          font-family: 'Athelas';
        }
        p {
          font-family: 'Athelas';
          margin-top: 10px;
        }
      }
    }
  }
}

/*------------------------------------------- look_block -------------------------------------------*/
.look_block {
  margin: 100px 0;
  .btn_block {
    margin-top: 35px;
    text-align: center;
  }
  .mt80 {
    margin-top: 80px;
  }
  .item {
    height: 500px;
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    &:hover {
    .text {
      height: 200px;
    }
    }
    .text {
      transition: all 0.3s;
      position: absolute;
      height: 100px;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      .sub_text {
        color: #fff;
        text-transform: uppercase;
        padding-right: 35px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 3px;
          right: 0;
          height: 12px;
          width: 12px;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
        }
      }
    }
  }
}

/*------------------------------------------- rewards_container -------------------------------------------*/
.rewards_container {
  text-align: center;
  padding: 100px 0;
  background-image: url(../img/rewards-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  .divider_container {
    margin-top: 0;
    .text {
      h3 {
        color: #fff;
      }
      h2 {
        color: #fff;
      }
    }
  }
  .btn_1 {
    margin-top: 50px;
  }
}

/*------------------------------------------- partner_container -------------------------------------------*/
.partner_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    filter: grayscale(100%);
    &:hover {
      filter: grayscale(0);
    }
  }
}

/*------------------------------------------- franchise_container -------------------------------------------*/
.franchise_container {
  text-align: center;
  h4 {
    font-family: 'Athelas';
  }
  .franchise_block {
    margin-top: 50px;
    .franchise_item {
      .img_container {
        height: 360px;
        overflow: hidden;
        img {
          max-width: none;
        }
      }
      h4 {
        margin-top: 30px;
      }
      .btn_3 {
        margin-top: 30px;
      }
    }
  }
}

/*------------------------------------------- application_container -------------------------------------------*/
.application_container {
  padding: 70px 0;
  background: #511e1e;
  margin-top: 100px;
  text-align: center;
  h4 {
    color: #fff;
    font-size: 35px;
    line-height: 38px;
  }
  .btn_3 {
    margin-top: 30px;
  }
}

/*------------------------------------------- command_block -------------------------------------------*/
.command_block {
  text-align: center;
  margin: 100px 0;
  h4 {
    font-size: 45px;
    line-height: 48px;
  }
  img {
    display: block;
    margin: 50px 0;
  }
}

/*------------------------------------------- all_contacts -------------------------------------------*/
.all_contacts {
  background: #f2f2f2;
  padding-bottom: 100px;
  form {
    padding: 100px;
    background: #fff;
    text-align: center;
    position: relative;
    top: -500px;
    input {
      margin-top: 40px;
    }
    select {
      margin-top: 40px;
    }
    textarea {
      margin-top: 40px;
    }
    .capt {
      align-items: center;
      margin-top: 20px;
      input {
        margin-top: 0;
      }
    }
    .submit_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      & > div {
        width: 49%;
      }
      p {
        font-size: 14px;
        line-height: 17px;
        font-family: 'Athelas';
        color: #000;
        a {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
  .contacts {
    margin-top: -470px;
    .contact_item {
      margin-top: 80px;
      p {
        margin-top: 25px;
        .name {
          color: #bc916c;
        }
      }
      .tel {
        margin-top: 25px;
        padding-left: 40px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;
          background-image: url(../img/tel.png);
          -webkit-background-size: cover;
          background-size: cover;
        }
      }
      .mail {
        margin-top: 25px;
        padding-left: 40px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 2px;
          left: 0;
          width: 18px;
          height: 18px;
          background-image: url(../img/mail.png);
          -webkit-background-size: cover;
          background-size: cover;
        }
      }
    }
  }
}

/*------------------------------------------- personnel_container -------------------------------------------*/
.personnel_container {
  .personnel_item {
    margin-bottom: 100px;
    text-align: center;
    .img_container {
      height: 350px;
      overflow: hidden;
      img {
        max-width: none;
        height: 100%;
      }
    }
    h4 {
      margin-top: 30px;
      font-weight: bold;
    }
    p {
      margin-top: 20px;
      font-family: 'Athelas';
    }
    .icon_block {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .tel {
        display: inline-block;
        width: 45px;
        height: 45px;
        background-image: url(../img/personnel-tel.png);
        margin: 0 10px;
      }
      .mail {
        display: inline-block;
        width: 45px;
        height: 45px;
        background-image: url(../img/personnel-mail.png);
        margin: 0 10px;
      }
    }
  }
}

/*------------------------------------------- map_filter -------------------------------------------*/
.map_filter {
  margin: 100px 0;
  .flex_3_column {
    align-items: center;
  }
  .select {
    .text_menu {
      width: 100%;
    }
  }
  .review {
    text-align: right;
    color: #511e1e;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    padding-right: 35px;
    &:after {
      content: "";
      position: absolute;
      top: 1px;
      right: 0;
      width: 15px;
      height: 15px;
      border-top: 2px solid #511e1e;
      border-right: 2px solid #511e1e;
      transform:rotate(45deg);
    }
  }
}

/*------------------------------------------- map -------------------------------------------*/
.map {
  iframe {
    width: 100%;
    height: 500px;
  }
}

/*------------------------------------------- place_container -------------------------------------------*/
.place_container {
  margin-bottom: 200px;
  .place_item {
    margin-top: 70px;
    .img_container {
      height: 240px;
      overflow: hidden;
      padding: 10px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid #bd926c;
        display: block;
      }
      img {
        height: 100%;
        max-width: none;
      }
    }
    .place {
      font-family: 'Athelas';
      margin-top: 15px;
      position: relative;
      padding-left: 30px;
      color: #000;
      font-size: 20px;
      font-weight: bold;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 17px;
        height: 23px;
        background-image: url(../img/place.png);
        -webkit-background-size: cover;
        background-size: cover;
      }
    }
    .metro {
      font-family: 'Athelas';
      margin-top: 15px;
      color: #511e1e;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

/*------------------------------------------- review_container -------------------------------------------*/
.review_container {
  background: #f2f2f2;
  .give_feedback {
    padding: 70px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      width: 70%;
    }
  }
  .review_block {
    .review_item {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      background: #fff;
      padding: 70px;
      &.red {
        border: 1px solid #960014;
      }
      &.green {
        border: 1px solid #18a659;
      }
      &.yellow {
        border: 1px solid #f8f400;
      }
      .left {
        width: 80%;
        p {
          margin: 20px 0;
          font-family: 'Athelas';
          font-size: 20px;
        }
        .place {
          font-family: 'Athelas';
          position: relative;
          padding-left: 30px;
          color: #bc916c;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 17px;
            height: 23px;
            background-image: url(../img/place.png);
            background-size: cover;
          }
        }
      }
      .right {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .date {
          text-align: right;
          color: #bc916c;
          font-family: 'Athelas';
          font-size: 20px;
        }
        .stars {
          display: flex;
          justify-content: flex-end;
          li {
            margin: 0 5px;
            width: 27px;
            height: 25px;
            background-image: url(../img/star.png);
            &.active {
              background-image: url(../img/star-active.png);
            }
          }
        }
      }
    }
  }
  .btn_container {
    margin-top: 100px;
    padding-bottom: 150px;
    text-align: center;
  }
}

/*------------------------------------------- form_communication -------------------------------------------*/
.form_communication {
  background: #f2f2f2;
  .form {
    position: relative;
    top: -500px;
    padding: 100px;
    background: #fff;
    text-align: center;
    h3 {
      font-family: 'Comfortaa';
      color: #000;
    }
    .brown_border {
      margin: 50px 0;
      border: 4px solid #bc916c;
      display: inline-block;
      padding: 10px 90px 40px 90px;
      border-radius: 50px;
      text-align: left;
      h4 {
        margin-top: 30px;
      }
      .tel {
        position: relative;
        padding-left: 70px;
        &:before {
          content:"";
          position: absolute;
          top: -13px;
          left: 0;
          background-image: url(../img/personnel-tel.png);
          -webkit-background-size: cover;
          background-size: cover;
          height: 50px;
          width: 50px;
          display: inline-block;
        }
      }
      .mail {
        position: relative;
        padding-left: 70px;
        &:before {
          content:"";
          position: absolute;
          top: -13px;
          left: 0;
          background-image: url(../img/personnel-mail.png);
          -webkit-background-size: cover;
          background-size: cover;
          height: 50px;
          width: 50px;
          display: inline-block;
        }
      }
    }
  }
}

/*------------------------------------------- advantages_container -------------------------------------------*/
.advantages_container {
  margin-top: -210px;
  background-image: url(../img/advantages-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  .container {
    padding: 205px 0;
    position: relative;
    .advantages_item {
      padding: 10px;
      text-align: center;
      width: 350px;
      height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 50%;
      &:nth-of-type(1) {
        position: absolute;
        top: -40%;
        left: 0;
      }
      &:nth-of-type(2) {
        position: absolute;
        top: -40%;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-of-type(3) {
         position: absolute;
         top: -40%;
         right: 0;
       }
      &:nth-of-type(4) {
        position: absolute;
        bottom: -40%;
        left: 0;
      }
      &:nth-of-type(5) {
        position: absolute;
        bottom: -40%;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-of-type(6) {
        position: absolute;
        bottom: -40%;
        right: 0;
      }
      .number {
        font-family: 'Abril Fatface';
        font-size: 60px;
        line-height: 60px;
        color: #511e1e;
        margin-bottom: 25px;
      }
    }
  }
}

/*------------------------------------------- pride -------------------------------------------*/
.pride {
  text-align: center;
  background: #f2f2f2;
  padding: 290px 0 165px 0;
}

/*------------------------------------------- conditions_container -------------------------------------------*/
.conditions_container {
  border-bottom: 2px solid #bc916c;
  .conditions_item {
    border-top: 2px solid #bc916c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    h4 {
      width: 50%;
    }
  }
}

/*------------------------------------------- additional_info -------------------------------------------*/
.additional_info {
  position: relative;
  .bg_block {
    height: 600px;
    background-image: url(../img/additional-bg.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
  }
  .grey_border {
    position: relative;
    z-index: 10;
    background: #fff;
    border: 10px solid #aeaeae;
    padding: 0 60px 60px 60px;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    .item {
      width: 24%;
      text-align: center;
      margin: 70px 40px 0 40px;
      img {
        height: 160px;
      }
      p {
        margin-bottom: 25px;
      }
      h4 {
        margin-top: 50px;
      }
    }
  }
}

/*------------------------------------------- stages_container -------------------------------------------*/
.stages_container {
  background: #e8e8e8;
  padding: 100px 0 50px 0;
  .stages_block {
    display: flex;
    justify-content: space-between;
  }
  .white_bg {
    background: #fff;
    border-radius: 30px;
    padding: 40px;
    width: 35%;
    .caption {
      color: #bc916c;
      margin-top: 30px;
    }
    .divider {
      margin-top: 30px;
      background: #bc916c;
      height: 2px;
    }
  }
  .step_by_step {
    width: 55%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .step {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 10px;
      position: relative;
      &:after {
        content:"";
        position: absolute;
        height: 40px;
        width: 3px;
        background: #bc916c;
        display: inline-block;
        top: 147px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:nth-of-type(2) {
        &:before {
          content:"";
          position: absolute;
          top: -37px;
          left: 116px;
          width: 132px;
          height: 39px;
          background-color: transparent;
          background-image: url(../img/arc-2.png);
        }
      }
      &:nth-of-type(7) {
        &:after {
          width: 132px;
          height: 39px;
          background-color: transparent;
          background-image: url(../img/arc-1.png);
          left: 130%;
        }
      }
      &:nth-of-type(8) {
        &:after {
          display: none;
        }
      }
      &:nth-of-type(9) {
        &:after {
          display: none;
        }
      }
      p {
        font-size: 16px;
        font-family: 'Athelas';
        margin-top: 5px;
        text-align: center;
      }
    }
  }
}

/*------------------------------------------- our_advantages -------------------------------------------*/
.our_advantages {
  .item {
    margin-top: 30px;
    h4 {
      color: #bc916c;
      padding-left: 70px;
    }
    p {
      font-size: 24px;
      line-height: 27px;
    }
  }
}

/*------------------------------------------- receipts -------------------------------------------*/
.receipts {
  margin: 100px 0 200px 0;
  text-align: center;
  h2 {
    color: #bc916c;
    font-size: 130px;
    font-family: 'Comfortaa';
    line-height: 130px;
    margin-bottom: 40px;
  }
}

/*------------------------------------------- text_container -------------------------------------------*/
.text_container {
  margin: 100px 0 200px 0;
  p {
    font-family: 'Athelas';
    margin-top: 25px;
  }
  h4 {
    color: #bc916c;
    font-size: 24px;
    line-height: 27px;
    margin-top: 25px;
  }
  ol {
    list-style: decimal inside;
    li {
      font-family: 'Athelas';
      font-size: 18px;
      padding-left: 35px;
    }
  }
}

/*------------------------------------------- registration_container -------------------------------------------*/
.registration_container {
  margin-bottom: 200px;
  a {
    color: #bc916c;
  }
  .main_text {
    text-align: center;
    margin-top: 30px;
  }
  button {
    margin: 30px auto 0 auto;
    width: 310px;
    display: block;
  }
  .registration_block {
    margin-top: 50px;
    .item {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .w65 {
        width: 65px;
        input {
          padding: 0 28px;
        }
      }
      .w100 {
        width: 100px;
      }
      .w250 {
        width: 250px;
      }
      .caption {
        width: 30%;
        font-size: 28px;
        line-height: 30px;
      }
      .field {
        width: 60%;
        &.flex_block {
          display: flex;
          align-items: center;
          & > div {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

/*------------------------------------------- password_container -------------------------------------------*/
.password_container {
  margin: 100px 0 200px 0;
  h4 {
    font-family: 'Athelas';
    font-size: 24px;
    text-align: center;
  }
  .password_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    & > div {
      margin: 0 10px;
    }
  }
}

/*------------------------------------------- restaurant_icons -------------------------------------------*/
.restaurant_icons {
  margin-top: 40px;
  .item {
    display: flex;
    align-items: center;
    margin-top: 60px;
    .img_container {
      width: 80px;
      margin-right: 50px;
    }
    .text {
      font-size: 25px;
      line-height: 27px;
    }
  }
}

/*------------------------------------------- new_event -------------------------------------------*/
.new_event {
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    overflow: hidden;
    .img_block {
      overflow: hidden;
      height: 335px;
      img {
        height: 100%;
        max-width: none;
      }
    }
    .caption {
      font-size: 23px;
      margin: 25px 0;
    }
    p {
      margin-top: auto;
    }
  }
}

/*------------------------------------------- restaurant_review -------------------------------------------*/
.restaurant_review {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  .review {
    margin-top: 50px;
    .info {
      margin-top: 15px;
    }
    .text {
      margin-top: 15px;
      font-size: 22px;
      line-height: 25px;
    }
  }
}

/*------------------------------------------- review_btns -------------------------------------------*/
.review_btns {
  margin: 70px auto 0 auto;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*------------------------------------------- one_human_container -------------------------------------------*/
.one_human_container {
  position: relative;
  margin-top: 230px;
  background-color: #f2f2f2;
  text-align: center;
  padding-bottom: 200px;
  .bg_human {
    background-image: url(../img/bg-human.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    height: 370px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  .one_human {
    position: relative;
    z-index: 10;
    .btn_3 {
      margin-top: 70px;
    }
    .white_bg {
      margin: 0 auto;
      background: #fff;
      padding: 0 30px 30px 30px;
      text-align: center;
      width: 450px;
      position: relative;
      p {
        margin-top: 5px;
      }
      &:before {
        content:"";
        display: block;
        border: 20px solid #fff;
        position: absolute;
        top: -30px;
        bottom: -30px;
        left: -30px;
        right: -30px;
      }
    }
  }
}

/*------------------------------------------- bonus_container -------------------------------------------*/
.bonus_container {
  .item {
    display: flex;
    align-items: center;
    margin-top: 35px;
    &:last-of-type {
      .number {
        &:after {
          display: none;
        }
      }
    }
    .number {
      min-width: 130px;
      min-height: 130px;
      border: 2px solid #000;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Abril Fatface';
      font-size: 60px;
      margin-right: 60px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 37px;
        width: 2px;
        background: #000;
      }
    }
    .text {
      font-size: 28px;
      line-height: 31px;
      font-family: 'Athelas';
    }
  }
}

/*------------------------------------------- card_container -------------------------------------------*/
.card_container {
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #f0f0f0;
    border-radius: 50%;
  }
  h4 {
    text-transform: uppercase;
  }
  p {
    font-size: 20px;
    font-family: 'Athelas';
  }
  .card_block {
    align-items: flex-end;
    position: relative;
    z-index: 10;
    .card {
      text-align: center;
    }
    .scoring {
      margin-top: 100px;
      p {
        padding: 10px 20px;
        background: #fff;
        display: inline-block;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }
  .info_container {
    margin-top: 100px;
    h4 {
      font-weight: bold;
    }
    .important {
      padding-left: 100px;
      position: relative;
      z-index: 10;
      &:before {
        content: "";
        display: inline-block;
        width: 69px;
        height: 69px;
        background-image: url(../img/important-icon.png);
        -webkit-background-size: cover;
        background-size: cover;
        position: absolute;
        top: -12px;
        left: 0;
      }
    }
    .question {
      padding-left: 100px;
      position: relative;
      z-index: 10;
      &:before {
        content: "";
        display: inline-block;
        width: 69px;
        height: 69px;
        background-image: url(../img/question-icon.png);
        -webkit-background-size: cover;
        background-size: cover;
        position: absolute;
        top: -12px;
        left: 0;
      }
    }
  }
  .btn_container {
    position: relative;
    z-index: 10;
    margin-top: 100px;
    text-align: center;
  }
}

/*------------------------------------------- certificate_container -------------------------------------------*/
.certificate_container {
  p {
    font-size: 28px;
    line-height: 31px;
    font-family: 'Athelas';
    margin-bottom: 40px;
  }
}

/*------------------------------------------- competition_container -------------------------------------------*/
.competition_container {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
  h4 {
    font-size: 36px;
    line-height: 39px;
  }
  .competition_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
  }
}

/*------------------------------------------- personal_area_container -------------------------------------------*/
.personal_area_container {
  margin-top: 250px;
  h2 {
    text-align: center;
    font-size: 40px;
  }
  .personal_area {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    .sidebar {
      width: 30%;
      .img_container {
        height: 400px;
        overflow: hidden;
      }
      .btn_3 {
        width: 100%;
        text-align: center;
        margin-top: 40px;
      }
      .btn_2 {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
      }
      .caption_block {
        background: #bc916c;
        padding: 5px 10px;
        color: #fff;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
      }
      .icons_cobtainer {
        margin-top: 30px;
        display: flex;
        align-items: center;
        .icon {
          width: 86px;
          height: 86px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      .competition {
        .item {
          display: flex;
          margin-top: 15px;
          .name {
            width: 70%;
          }
          .number {
            width: 30%;
            text-align: center;
          }
        }
      }
    }
    .main {
      width: 65%;
      h4 {
        font-size: 36px;
        line-height: 39px;
        text-transform: uppercase;
      }
      .personal_info {
        border-bottom: 2px solid #000;
        padding-bottom: 40px;
        .divider {
          color: #fff;
          background: #bc916c;
          padding: 20px 25px;
          margin-top: 40px;
          font-size: 24px;
          line-height: 27px;
        }
        .personal_item {
          display: flex;
          align-items: center;
          margin-top: 40px;
          p {
            width: 345px;
            font-size: 20px;
            line-height: 23px;
            text-transform: uppercase;
          }
          span {
            font-size: 24px;
            line-height: 29px;
            font-family: 'Athelas';
          }
        }
      }
      .my_cards {
        margin-top: 40px;
        .card {
          margin-top: 30px;
          .text {
            p {
              font-size: 36px;
              line-height: 39px;
              margin-bottom: 45px;
              span {
                display: block;
                font-size: 20px;
                line-height: 23px;
              }
            }
          }
        }
      }
      .checkbox_container {
        .personal_information_form {
          margin-top: 20px;
        }
      }
    }
  }
}

/*------------------------------------------- news_block -------------------------------------------*/
.news_block {
  margin-top: 100px;
  margin-bottom: 200px;
  h2 {
    text-align: center;
    font-size: 40px;
    line-height: 43px;
  }
  .news {
    margin-top: 60px;
    .news_item {
      overflow: hidden;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 2px solid #000;
      padding-bottom: 20px;
      .img_container {
        height: 335px;
        overflow: hidden;
        img {
          height: 100%;
          max-width: none;
        }
      }
      h4 {
        margin-top: 30px;
        text-align: center;
      }
      .info {
        margin-top: auto;
        font-family: 'Athelas';
      }
    }
  }
}

/*------------------------------------------- slider_container -------------------------------------------*/
.slider_container {
  margin-top: 40px;
  .main_slider {
    img {
      width: 100%;
    }
  }
}

/*------------------------------------------- menu_container -------------------------------------------*/
.menu_container {
  margin-top: 160px;
  .menu {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 25%;
      margin-top: 20px;
      &.active {
        a {
          text-decoration: underline;
          color: #511e1e;
        }
      }
      a {
        font-size: 23px;
        line-height: 23px;
        text-transform: uppercase;
        color: #000;
      }
    }
  }
}

/*------------------------------------------- all_menu -------------------------------------------*/
.all_menu {
  h2 {
    text-align: center;
    margin: 50px 0 35px 0;
  }
  .menu_block {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 32%;
      margin-top: 22px;
      margin-right: 22px;
      display: flex;
      flex-direction: column;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      .img_container {
        height: 230px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      p {
        margin-top: 25px;
        text-align: center;
        padding: 0 20px;
      }
      span {
        display: block;
        text-align: center;
        padding: 0 20px;
        font-size: 13px;
      }
      .price_block {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        .price {
          font-size: 31px;
          line-height: 34px;
        }
        .btn_3 {
          padding: 5px 15px;
          font-size: 15px;
          width: 150px;
          text-align: center;
        }
      }
    }
  }
}

/*------------------------------------------- delivery_info -------------------------------------------*/
.delivery_info {
  margin: 100px 0 200px 0;
  p {
    margin-top: 20px;
  }
  .red_color {
    color: #511e1e;
  }
  a {
    color: #000;
    text-decoration: underline;
  }
}

/*------------------------------------------- fixed_block -------------------------------------------*/
.fixed_block {
  z-index: 100;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #21201e;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  .btn_3 {
    padding: 5px 25px;
    font-size: 15px;
  }
  .price {
    color: #fff;
  }
}

/*------------------------------------------- basket_divider -------------------------------------------*/
.basket_divider {
  margin-top: 215px;
}

/*------------------------------------------- basket_container -------------------------------------------*/
.basket_container {
  margin-bottom: 200px;
  form .personal_information_form {
    width: 40px;
    height: 40px;
  }
  form .personal_information_form span {
    position: static;
  }
  .additional_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0 0 0;
    input {
      width: 250px;
      margin-right: 15px;
    }
    .btn_2 {
      width: 250px;
      text-align: center;
      padding: 13px 20px;
      margin-right: 15px;
      font-size: 16px;
    }
    .btn_3 {
      width: 250px;
      text-align: center;
      padding: 13px 20px;
      font-size: 16px;
    }
  }
  .close {
    width: 70px;
    display: flex;
    align-items: center;
  }
  .name {
    flex-grow: 1;
    display: flex;
    align-items: center;
    &.tar {
      justify-content: flex-end;
    }
  }
  .number {
    width: 210px;
    justify-content: center;
    display: flex;
    align-items: center;
    span {
      font-size: 26px;
      line-height: 29px;
      font-weight: bold;
      margin: 0 15px;
    }
  }
  .price {
    width: 150px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    span {
      font-size: 30px;
      line-height: 33px;
      font-weight: bold;
    }
  }
  .caption {
    border-bottom: 2px solid #bc916c;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #bc916c;
      padding: 10px 0;
      .close_btn {
        width: 41px;
        height: 41px;
        background-image: url(../img/close.png);
        -webkit-background-size: cover;
        background-size: cover;
        cursor: pointer;
        display: inline-block;
      }
      .img_container {
        width: 230px;
        height: 150px;
        overflow: hidden;
        margin-right: 20px;
      }
      .text  {
        font-size: 26px;
        line-height: 29px;
      }
      .plus {
        width: 41px;
        height: 41px;
        background-image: url(../img/plus.png);
        -webkit-background-size: cover;
        background-size: cover;
        cursor: pointer;
      }
      .minus {
        width: 43px;
        height: 41px;
        background-image: url(../img/minus.png);
        -webkit-background-size: cover;
        background-size: cover;
        cursor: pointer;
      }
    }
  }
}

/*------------------------------------------- basket_registration_container -------------------------------------------*/
.basket_registration_container {
  margin-bottom: 200px;
  .container {
    display: flex;
    justify-content: space-between;
    form {
      width: 47%;
      h4 {
        color: #bc916c;
        font-size: 36px;
        line-height: 39px;
        margin-bottom: 30px;
        text-align: center;
      }
      input {
        margin-top: 10px;
      }
    }
  }
  .reg_bloc {
    display: flex;
    flex-direction: column;
    .btn_2 {
      font-size: 20px;
      padding: 14px 20px;
    }
    .btn_container_2 {
      margin-top: auto;
    }
    .btn_container {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: auto;
        margin-right: 10px;
      }
      a {
        color: #000;
      }
    }
  }
  .quick_order {
    .btn_container {
      margin-top: 30px;
      text-align: right;
      button {
        width: auto;
      }
    }
    .item {
      display: flex;
      align-items: center;
      .title {
        min-width: 150px;
        margin-top: 10px;
      }
    }
  }
}

/*------------------------------------------- photo_divider -------------------------------------------*/
.photo_divider {
  margin-top: 260px;
}

/*------------------------------------------- menu_container_mobile -------------------------------------------*/
.menu_container_mobile {
  display: none;
  .menu_item {
    display: block;
    position: relative;
    margin-bottom: 20px;
    h4 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      color: #fff;
      width: 300px;
      text-align: center;
    }
  }
}

/*------------------------------------------- mobile_only -------------------------------------------*/
.mobile_only {
  display: none;
}

/*------------------------------------------- photo_container_mobile -------------------------------------------*/
.photo_container_mobile {
  display: none;
  padding: 0 20px;
  .slick-prev {
    width: 38px;
    height: 38px;
    background-image: url(../img/left-arrow.png);
    z-index: 10;
    left: -15px;
    &:before {
      display: none;
    }
  }
  .slick-next {
    width: 38px;
    height: 38px;
    background-image: url(../img/right-arrow.png);
    z-index: 10;
    right: -16px;
    &:before {
      display: none;
    }
  }
}

/*------------------------------------------- top -------------------------------------------*/
#top {
  position: absolute;
  top: 0;
  left: 0;
}














