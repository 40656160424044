

//--------------------------------------------------- 1500px ------------------------------------------------------//
@media screen and (max-width: 1500px) {
  .one_event .left_arrow {
    left: -40px;
  }
  .one_event .right_arrow {
    right: -40px;
  }
  header .authorization {
    left: auto;
    right: 17px;
    top: 79px;
    animation: none;
    display: flex;
    align-items: flex-start;
  }
  header .authorization a.in {
    margin-bottom: 0;
    margin-right: 10px;
  }
  header .authorization.log_out {
    top: 62px;
    right: 0;
  }
  header .nav.header_left .top .btn_2 {
    margin-right: 10px;
  }
  header .nav.header_left .top {
    justify-content: flex-start;
  }
  header .nav.header_right {
    width: 39%;
  }
  header .nav.header_left {
    width: 39%;
  }
  header .nav.header_left .bottom p {
    font-size: 15px;
  }
  header .nav.header_left .top .caption {
    font-size: 15px;
  }
  header .nav.header_right .bottom p {
    font-size: 15px;
  }
  header .logo {
    margin: 0 20px;
  }
  header .nav.header_right .top .btn_2 {
    margin-right: 5px;
  }
}

//--------------------------------------------------- 1200px ------------------------------------------------------//
@media screen and (max-width: 1200px) {
  .all_menu .menu_block .item {
    width: 31%;
  }
  .menu_container .menu li a {
    font-size: 18px;
  }
  .personal_area_container .personal_area .sidebar .btn_2 {
    font-size: 13px;
  }
  .look_block .item .text {
    font-size: 16px;
  }
  .main_banner.one_restaurant .right_bg .white_bg {
    padding: 30px;
    margin-left: 45px;
    width: 370px;
  }
  .stages_container .step_by_step .step:nth-of-type(7):after {
    left: 112%;
  }
  .stages_container .step_by_step .step:nth-of-type(2):before {
    top: -47px;
    left: 84px;
  }
  .additional_info .grey_border .item {
    width: 37%;
  }
  .advantages_container .container .advantages_item {
    width: 300px;
    height: 300px;
  }
  .advantages_container .container .advantages_item h4 {
    font-size: 20px;
  }
  header .nav {
    width: 330px;
  }
  header .nav li a {
    font-size: 13px;
  }
  .review_container .give_feedback {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .review_container .give_feedback .btn_3 {
    margin-top: 20px;
  }
  .review_container .review_block .review_item .left {
    width: 70%;
  }
  .review_container .review_block .review_item .right {
    width: 30%;
  }
  .review_container .give_feedback p {
    width: 100%;
    text-align: center;
  }
}

//--------------------------------------------------- 992px ------------------------------------------------------//
@media screen and (max-width: 992px) {
  .slider_container {
    margin-top: 130px;
  }
  .basket_registration_container .reg_bloc .btn_container_2 {
    margin-top: 20px;
  }
  .basket_registration_container .reg_bloc .btn_2 {
    width: 100%;
    text-align: center;
  }
  .mobile_no {
    display: none !important;
  }
  .basket_container .order .item .close_btn {
    width: 30px;
    height: 30px;
  }
  header .authorization a {
    border-radius: 0;
  }
  .delivery_info {
    margin: 50px 0 100px 0;
  }
  .all_menu .menu_block .item {
    width: 100%;
    margin-right: 0;
  }
  .slider_container .main_slider .slick-dots li {
    display: inline-block;
    margin: 10px;
    border: 1px solid #000;
  }
  .slider_container .main_slider .slick-dots {
    width: 100%;
    transform: translateY();
    left: 0;
    right: 0;
    top: auto;
    bottom: -60px;
  }
  .slider_container .main_slider .slick-slide .text h2 {
    font-size: 30px;
    line-height: 33px;
  }
  .slider_container .main_slider .slick-slide .img {
    width: 100%;
  }
  .slider_container .main_slider .slick-slide .text {
    width: 100%;
  }
  .slider_container .main_slider .slick-slide {
    display: block;
    height: 500px;
  }
  .slider_container .slider_preview {
    display: none;
  }
  .menu_container {
    display: none;
  }

  header .big_container {
    position: static;
  }
  header .nav.header_right {
    display: none;
  }
  header .nav.header_left {
    display: none;
  }
  .competition_container  {
    margin: 50px 0 100px 0;
  }
  .competition_container .competition_block > div {
    margin-top: 30px;
  }
  .competition_container .competition_block {
    display: block;
  }
  .card_container .info_container .question {
    width: 100%;
    margin-top: 30px;
  }
  .card_container .info_container .important {
    width: 100%;
  }
  .card_container .info_container {
    display: block;
  }
  .card_container .card_block .card {
    margin-top: 50px;
  }
  .card_container .card_block .scoring {
    display: none;
  }
  .card_container .card_block {
    display: block;
  }
  .card_container:before {
    display: none;
  }
  .main_banner.personal_area .login {
    width: 100%;
    padding: 30px;
  }
  .main_banner.personal_area {
    padding-top: 145px;
    padding-bottom: 50px;
  }
  .look_block .item .text .sub_text {
    padding: 0 10px;
    text-align: center;
  }
  .look_block .item .text .sub_text:after {
    display: none;
  }
  .one_human_container .one_human .white_bg {
    width: 90%;
  }
  .one_human_container .one_human .white_bg:before {
    display: none;
  }
  .one_human_container {
    margin-top: 100px;
    padding-bottom: 100px;
  }
  .review_btns .btn_3 {
    margin-bottom: 30px;
  }
  .review_btns {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .restaurant_review {
    width: 100%;
  }
  .new_event .item {
    margin-top: 50px;
  }
  .main_banner.one_restaurant .right_bg .white_bg {
    width: 90%;
    margin: 0 auto;
  }
  .main_banner.one_restaurant .right_bg .white_bg:before {
    display: none;
  }
  .main_banner.one_restaurant .right_bg {
    width: 100%;
    padding: 150px 0 50px 0;
  }
  .main_banner.one_restaurant .left_bg {
    display: none;
  }
  .main_banner.one_restaurant {
    display: block;
    height: auto;

  }
  .stages_container .step_by_step {
    display: none;
  }
  .stages_container .white_bg {
    padding: 20px;
    width: 100%;
  }
  .stages_container .stages_block {
    display: block;
  }
  .stages_container {
    padding: 50px 0;
  }
  .additional_info .grey_border .item {
    width: 100%;
    margin: 30px 0 0 0;
  }
  .additional_info .grey_border {
    padding: 0 30px 30px 30px;
  }
  .additional_info .grey_border .item img {
    height: auto;
  }
  .additional_info .bg_block {
    display: none;
  }
  .conditions_container .conditions_item h4 {
    width: 100%;
  }
  .conditions_container .conditions_item {
    display: block;
  }
  .pride {
    padding: 70px 0 70px 0;
  }
  .advantages_container .container .advantages_item h4 {
    font-size: 18px;
    line-height: 18px;
  }
  .advantages_container .container .advantages_item .number {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 5px;
  }
  .advantages_container .container .advantages_item {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
  .advantages_container .container {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -420px;
  }
  .advantages_container .container .advantages_item {
    position: static !important;
    transform: translate(0,0) !important;
  }
  .advantages_container {
    background-image: none;
    background: #f2f2f2;
  }
  .form_communication .form .brown_border .tel {
    font-size: 20px;
  }
  .form_communication .form .brown_border .mail {
    font-size: 20px;
  }
  .form_communication .form .brown_border {
    border: none;
    padding: 0;
  }
  .form_communication .form h3 {
    font-size: 40px;
  }
  .form_communication .form {
    padding: 30px;
  }
  .review_container .review_block .review_item .left {
    width: 60%;
  }
  .review_container .review_block .review_item .right {
    width: 40%;
  }
  .video_container .video_item {
    height: auto;
    margin-bottom: 0;
  }
  .photo_container .photo_item {
    height: auto;
    margin-bottom: 0;
  }
  .stock_2 {
    background-image: url(../img/stock_2.png);
    -webkit-background-size: cover;
    background-size: cover;
    padding: 60px 15px;
    margin-top: 0;
  }
  .stock_2 .stock_2_container {
    position: static;
  }
  .stock_2 img {
    display: none;
  }
  .menu_container .menu_item {
    margin-bottom: 30px;
  }
  .flex_4_column > a {
    width: 49%;
  }
  .flex_4_column > div {
    width: 49%;
  }
  .main_banner.main h1 {
    font-size: 50px;
    line-height: 63px;
  }
  .main_banner.main {
    margin-top: 100px;
    padding: 50px 0 30px 0;
  }
  .main_banner.main h4 {
    font-size: 16px;
    line-height: 19px;
    margin-top: 30px;
  }
  header.white .nav {
    display: none;
  }
  header .nav {
    display: none;
  }
  header .authorization {
    position: static;
  }
  header .container {
    position: static;
  }
  .nav_container {
    display: block;
  }
  header {
    height: 100px;
  }
  header .authorization a.reg {
    display: none;
  }
  header .authorization a.in_mobile {
    display: inline-block;
  }
  header .authorization a.in {
    display: none;
  }
  header .logo {
    width: 185px;
  }
  h1 {
    font-size: 50px;
  }
  .main_banner.developments {
    padding-top: 190px;
    padding-bottom: 90px;
  }
  .divider_container .icon {
    display: none;
  }
  .divider_container .container {
    justify-content: center;
  }
  .divider_container {
    margin: 0 0 50px 0;
  }
  .divider_container .text {
    padding-top: 50px;
  }
  h2 {
    font-size: 40px;
  }
  h4 {
    font-size: 25px;
  }
  .filter_container .address_block {
    flex-direction: column;
  }
  .filter_container .address_block h4 {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .filter_container .address_block .date {
    margin-left: 0;
    margin-top: 50px;
  }
  .filter_container .calendar_block li {
    display: none;
    min-width: 100px;
    height: 100px;
    background: #21201e;
    border-radius: 50%;
    margin-bottom: 20px;
    font-size: 25px;
  }
  .filter_container .calendar_block li.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .filter_container .calendar_block {
    justify-content: center;
    margin-top: 30px;
  }
  .filter_container .calendar_block li .day {
    margin-bottom: 0;
  }
  .filter_container .calendar_block:after {
    bottom: 60px;
  }
  .filter_container .calendar_block:before {
    bottom: 60px;
  }
  .filter_container .filter_mobile {
    display: block;
  }
  .filter_container .filter_btn_block {
    margin-top: 20px;
    display: none;
  }
  .flex_3_column > div {
    width: 49%;
  }
  footer .nav {
    display: block;
    text-align: center;
  }
  footer .nav li {
    margin-bottom: 22px;
  }
  footer .bottom_block {
    flex-direction: column;
    margin-top: 50px;
  }
  footer .bottom_block .social a {
    margin: 0 10px;
  }
  footer .bottom_block .agreement {
    margin-top: 22px;
    margin-left: 0;
  }
  footer .bottom_block p {
    margin-top: 22px;
  }
  footer .bottom_block .social {
    margin-top: 22px;
  }
  footer .bottom_block .social a img {
    width: 50px;
  }
  footer .bottom_block .language a {
    width: 50px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
  }
  .flex_3_column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
      width: 49%;
    }
    & > a {
      width: 49%;
    }
  }
  .report_container.video .report_item .img_block {
    height: auto;
  }
  .look_block .item {
    margin-top: 20px;
  }
  .main_banner.restaurant {
    margin-top: 100px;
  }
  .map_filter .review {
    text-align: left;
    margin-top: 20px;
  }
  .registration_container .registration_block .item {
    display: block;
  }
  .registration_container .registration_block .item .caption {
    width: 100%;
  }
  .registration_container .registration_block .item .field {
    width: 100%;
    margin-top: 10px;
  }
  .registration_container .registration_block .item .field.flex_block {
    display: block;
  }
  .registration_container .registration_block .item .field.flex_block > div {
    margin-right: 0;
    margin-top: 10px;
  }
  .registration_container {
    margin-bottom: 100px;
  }
  .main_banner.registration h1 {
    font-size: 37px;
  }
  .password_container {
    margin: 50px 0 100px 0;
  }
  .password_container .password_flex {
    display: block;
  }
  .password_container .password_flex > div {
    margin: 20px 0 0 0;
  }
  header .authorization.log_out a.in_mobile {
    background-image: url(../img/logout.png);
  }
  .personal_area_container h2 {
    font-size: 30px;
  }
  .personal_area_container .personal_area {
    display: block;
  }
  .personal_area_container .personal_area .sidebar {
    width: 100%;
  }
  .personal_area_container .personal_area .main {
    width: 100%;
    margin-top: 40px;
  }
  .personal_area_container .personal_area .main h4 {
    font-size: 30px;
  }
  .personal_area_container .personal_area .main .personal_info .personal_item {
    margin-top: 20px;
  }
  .news_block .news .news_item {
    margin-top: 30px;
  }
  .news_block {
    margin-bottom: 100px;
  }
  .fixed_block {
    display: flex;
  }
  .basket_divider {
    margin: 100px 0 50px 0;
  }
  .basket_container .order .item {
    display: block;
  }
  .basket_container .caption .close {
    display: none;
  }
  .basket_container .caption .name {
    display: none;
  }
  .basket_container .caption .number {
    display: none;
  }
  .basket_container .caption .price {
    display: none;
  }
  .basket_container .caption {
    padding: 0;
  }
  .basket_container .name {
    display: block;
  }
  .basket_container .close {
    width: auto;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .basket_container .order .item .img_container {
    width: 100%;
    height: auto;
  }
  .basket_container .order .item .img_container img {
    width: 100%;
  }
  .basket_container .order .item .text {
    text-align: center;
    margin: 20px 0;
  }
  .basket_container .number {
    width: 100%;
  }
  .basket_container .price {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
  .basket_container .additional_block {
    display: block;
  }
  .basket_container .additional_block .number {
    margin-top: 40px;
  }
  .basket_container .additional_block input {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
  .basket_container .additional_block .btn_2 {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
  .basket_container .additional_block .btn_3 {
    width: 100%;
    margin-top: 20px;
  }
  .basket_container {
    margin-bottom: 100px;
  }
  .basket_registration_container .container {
    display: block;
  }
  .basket_registration_container .container form {
    width: 100%;
  }
  .basket_registration_container .container form h4 {
    margin-bottom: 10px;
  }
  .basket_registration_container .reg_bloc .btn_container {
    display: block;
    margin-top: 10px;
  }
  .basket_registration_container .reg_bloc .btn_container button {
    width: 100%;
    margin-right: 0;
  }
  .basket_registration_container .reg_bloc .btn_container a {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  .basket_registration_container .container form.quick_order {
    margin-top: 50px;
  }
  .basket_registration_container .quick_order .item {
    display: block;
  }
  .basket_registration_container .quick_order .btn_container button {
    width: 100%;
  }
  .basket_registration_container .quick_order .btn_container {
    margin-top: 10px;
  }
  .basket_registration_container {
    margin-bottom: 100px;
  }
  .divider_container.photo_divider {
    margin: 100px 0 50px 0;
  }
  .personal_area_container {
    margin-top: 160px;
  }
  h1 span {
    display: block;
    font-size: 74px;
  }
  .main_menu_container {
    display: none;
  }
  .container.full_width {
    padding: 0;
  }
  .stock_1 {
    height: auto;
    display: block;
  }
  .stock_1 .bg_img {
    position: static;
  }
  .stock_1 .dark_bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px;
  }
  .stock_1 .dark_bg p {
    font-size: 12px;
    line-height: 14px;
    margin-top: 0;
  }
  .btn_1 {
    font-size: 17px;
  }
  .video_container {
    margin-bottom: 80px;
  }
  .mobile_only {
    display: block;
  }
  .menu_container_mobile {
    display: block;
  }
  .stock_2 .stock_2_container .divider_container {
    display: none;
  }
  .stock_2 .stock_2_container .btn_1 {
    margin-top: 30px;
  }
  .photo_container .photo_item:before {
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
  }
  .video_container .video_item:after {
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
  }
  .photo_container .flex_2_column {
    display: none;
  }
  .video_container .photo_item:before {
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
  }
  .video_container .flex_2_column {
    display: none;
  }
  .photo_container_mobile {
    display: block;
  }
  footer .container {
    display: flex;
    justify-content: space-between;
  }
  footer .nav {
    text-align: left;
    width: 49%;
  }
  footer .bottom_block {
    margin-top: 0;
    width: 49%;
    align-items: flex-end;
  }
  footer .bottom_block .agreement {
    text-align: right;
  }
  .main_banner.restaurant .select {
    margin-top: 55px;
  }
  .main_banner.restaurant h1 span {
    font-size: 74px;
  }
  .main_banner.restaurant h1 {
    font-size: 50px;
    line-height: 63px;
  }
  .divider_container.main_divider .text h3 {
    color: #a32135;
  }
  .divider_container.main_divider {
    margin: 0 0 5px 0;
  }
  .divider_container.main_divider .text {
    position: relative;
    padding-top: 30px;
  }
  .divider_container.main_divider .text:before {
    content:"";
    height: 22px;
    width: 352px;
    position: absolute;
    top: 60px;
    right: 110%;
    background-image: url(../img/divider.png);
    display: inline-block;
  }
  .divider_container.main_divider .text:after {
    content:"";
    height: 22px;
    width: 352px;
    position: absolute;
    top: 60px;
    left: 110%;
    background-image: url(../img/divider.png);
    display: inline-block;
  }
  .basket_registration_container .container form input {
    padding: 0 0 0 15px;
  }
}

//--------------------------------------------------- 768px ------------------------------------------------------//
@media screen and (max-width: 768px) {
  .all_menu .menu_block .item .price_block {
    padding: 20px 5px;
  }
  .slider_container .main_slider .slick-slide {
    height: auto;
  }
  .slider_container .main_slider .slick-slide .img {
    display: none;
  }
  .news_block .news {
    margin-top: 0;
  }
  .news_block {
    margin-top: 50px;
  }
  .personal_area_container .personal_area .sidebar .caption_block {
    font-size: 16px;
  }
  .personal_area_container {
    margin-top: 50px;
  }
  .personal_area_container .personal_area .main .personal_info .personal_item p{
    width: 100%;
  }
  .personal_area_container .personal_area .main .personal_info .personal_item {
    display: block;
  }
  .certificate_container {
    text-align: center;
  }
  .certificate_container .btn_3 {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .certificate_container p {
    text-align: center;
    margin-bottom: 20px;
  }
  .card_container .btn_4 {
    font-size: 12px;
  }
  .card_container {
    padding-top: 50px;
  }
  .bonus_container .item .text {
    text-align: center;
  }
  .bonus_container .item .number:after {
    display: none;
  }
  .bonus_container .item .number {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  .main_banner.personal_area .login .btn_4 {
    font-size: 12px;
  }
  .bonus_container .item {
    display: block;
  }
  .review_container .btn_container {
    padding-bottom: 100px;
  }
  .review_container .review_block .review_item .right .stars {
    justify-content: flex-start;
    margin-top: 20px;
  }
  .review_container .review_block .review_item .right .date {
    text-align: left;
  }
  .review_container .review_block .review_item .right {
    width: 100%;
    margin-top: 20px;
  }
  .review_container .review_block .review_item .left {
    width: 100%;
  }
  .review_container .review_block .review_item {
    padding: 50px 20px;
    display: block;
  }
  .main_banner.review {
    padding-top: 165px;
    padding-bottom: 50px;
  }
  .place_container {
    margin-bottom: 100px;
  }
  .place_container .place_item .img_container img {
    height: auto;
    width: 100%;
  }
  .place_container .place_item .img_container {
    height: auto;
  }
  .filter_container .text_block {
    margin: 50px 0;
  }
  .main_banner.restaurant {
    padding: 50px 0;
  }
  .map_filter {
    margin: 50px 0;
  }
  .map_filter .select {
    margin-top: 20px;
  }
  .about_description {
    margin-top: 170px;
  }
  .franchise_container .franchise_block .franchise_item {
    margin-top: 40px;
  }
  .partner_container {
    flex-wrap: wrap;
  }
  .partner_container > div {
    width: 49%;
    text-align: center;
  }
  .rewards_container {
    padding: 50px 0;
  }
  .look_block .item {
    height: 300px;
  }
  .number_block .white_bg .numbers .number_item .num {
    font-size: 60px;
    line-height: 60px;
  }
  .number_block .white_bg .numbers {
    justify-content: center;
    flex-wrap: wrap;
  }
  .number_block .white_bg .numbers .number_item {
    width: 40%;
    margin: 20px 10px 0 10px;
  }
  .number_block .white_bg .divider {
    justify-content: center;
  }
  .number_block .white_bg .divider .icon {
    display: none;
  }
  .one_event {
    padding-bottom: 100px;
  }
  .one_event .img_block {
    height: auto;
  }
  .one_event:before {
    display: none;
  }
  .one_event .left_arrow {
    left: 20px;
  }
  .one_event .right_arrow {
    right: 20px;
  }
  .description_block_2 {
    margin-bottom: 100px;
  }
  .one_video iframe {
    height: 250px;
  }
  .one_video:after {
    display: none;
  }
  .gallery .gallery_item img {
    width: 100%;
  }
  .pagination {
    margin-bottom: 100px;
  }
  .report_container .report_item .img_block {
    height: auto;
  }
  .flex_3_column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
    & > a {
      width: 100%;
    }
  }
  .flex_3_column > div {
    width: 100%;
  }
  .developments_container .developments_item .img_container {
    height: auto;
  }
  .developments_container .developments_item .img_container img {
    height: auto;
    width: 100%;
  }

  .menu_container .menu_item {
    margin-bottom: 70px;
  }
  .look_block {
    margin: 50px 0;
  }
  .main_banner.contacts {
    padding-bottom: 490px;
  }
  .all_contacts form {
    padding: 30px;
  }
  .all_contacts form .submit_block > div {
    width: 100%;
  }
  .all_contacts form .submit_block button {
    margin-bottom: 20px;
  }
  .all_contacts form input {
    margin-top: 20px;
  }
  .all_contacts .contacts .contact_item {
    margin-top: 60px;
  }
}



//--------------------------------------------------- 480px ------------------------------------------------------//
@media screen and (max-width: 480px) {
  .form_communication .form .brown_border {
    margin-top: 0;
  }
  .receipts {
    margin: 50px 0 100px 0;
  }
  .receipts h2 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .our_advantages .item h4 {
    padding-left: 0;
  }
  .advantages_container .container {
    justify-content: center;
  }
  .form_communication .form .brown_border .tel {
    font-size: 12px;
  }
  .form_communication .form .brown_border .mail {
    font-size: 12px;
  }

  .select .text_menu {
    width: 270px;
  }
  .filter_container .filter_mobile {
    width: 270px;
  }
  .more_btn .more {
    padding: 20px;
    font-size: 14px;
  }
  .number_block .white_bg .numbers .number_item {
    width: 100%;
  }
}

//--------------------------------------------------- 374px ------------------------------------------------------//
@media screen and (max-width: 374px) {
  .flex_4_column > a {
    width: 100%;
  }
  .flex_4_column > div {
    width: 100%;
  }
  header .logo {
    width: 150px;
  }
  header.right {
    transform: translateX(261px);
  }
  .flex_2_column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
    & > a {
      width: 100%;
    }
  }
}