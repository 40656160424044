html {
  overflow-x:hidden;
}
body {
  font-family: 'Comfortaa';
  color: #000;
  font-size: 18px;
  line-height: 21px;
  position: relative;
}
h1 {
  font-size: 60px;
  line-height: 63px;
  color: #fff;
  font-family: 'Adlery Pro';
  span {
    display: block;
    font-size: 155px;
  }
}
h2 {
  font-size: 60px;
  line-height: 60px;
  font-family: 'Citrica Cyrillic';
  text-transform: uppercase;
}
h3 {
  color: #bc916c;
  font-size: 45px;
  line-height: 45px;
  font-family: 'Adlery Pro';
}
h4 {
  font-size: 28px;
  line-height: 31px;
}

/*------------------------------------------- flex_2_column -------------------------------------------*/
.flex_2_column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 49%;
  }
  & > a {
    width: 49%;
  }
}

/*------------------------------------------- flex_3_column -------------------------------------------*/
.flex_3_column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 32%;
  }
  & > a {
    width: 32%;
  }
  & > input {
    width: 32%;
  }
}

/*------------------------------------------- flex_4_column -------------------------------------------*/
.flex_4_column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 23%;
  }
  & > a {
    width: 23%;
  }
}

/*------------------------------------------- main -------------------------------------------*/
main {
  transition: all 0.3s;
  &.active {
    transform: translateX(300px);
  }
}

/*------------------------------------------- mobile_nav -------------------------------------------*/
.mobile_nav {
  z-index: 101;
  position: fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 1000px;
  background: #21201e;
  border-bottom: 1px solid #fff;
  overflow-x: hidden;
  transition: all 0.3s;
  &.active {
    left: -300px;
  }
  li {
    a {
      display: block;
      font-size: 20px;
      color: #fff;
      text-align: center;
      padding: 12px 0;
      border-bottom: 1px solid #fff;
    }
  }
}

/*------------------------------------------- nav_container -------------------------------------------*/
.nav_container {
  display: none;
  .nav_icon {
    width: 40px;
    height: 30px;
    position: relative;
    cursor: pointer;
    &.open {
      span {
        &:nth-of-type(1) {
          top: 13px;
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          bottom: 14px;
          transform: rotate(-45deg);
        }
      }
    }
    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 20%;
      background: #633631;
      transition: all 0.3s ease-in-out;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 14px;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
  }
}

/*------------------------------------------- header -------------------------------------------*/
header {
  top: 0;
  position: fixed;
  height: 135px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  transition: all 0.3s;
  &.right {
    transform: translateX(300px);
  }
  &.black_bg {
    background: rgba(0,0,0,0.7);
  }
  &.active {
    background: #21201e;
    .authorization {
      a.in_mobile {
        background-image: url(../img/in-white.png);
      }
    }
    .nav_container {
      .nav_icon {
        span {
          background: #fff;
        }
      }
    }
    .nav {
      li {
        a {
          color: #fff;
        }
      }
    }
  }
  &.delivery {
    background: #21201e;
    .nav_container .nav_icon span {
      background: #fff;
    }
    .authorization a.in_mobile {
      background-image: url(../img/in-white.png);
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .big_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .nav {
    display: flex;
    width: 370px;
    justify-content: space-between;
    li {
      a {
        font-size: 15px;
        transition: all 0.3s;
        color: #000;
      }
    }
  }
  .logo {
    max-width: 280px;
    .static {
      display: block;
    }
    .scroll {
      display: none;
    }
    &.active {
      .static {
        display: none;
      }
      .scroll {
        display: block;
      }
    }
  }
  &.white {
    .nav_container {
      .nav_icon {
        span {
          background: #fff;
        }
      }
    }
    .authorization a.in_mobile {
      background-image: url(../img/in-white.png);
    }
    .nav {
      display: flex;
      li {
        a {
          color: #fff;
          &:hover {
            color: #bc916c;
          }
        }
      }
    }
  }
  &.red_bg {
    background: #511e1e;
    .nav_container {
      .nav_icon {
        span {
          background: #fff;
        }
      }
    }
    .authorization a.in_mobile {
      background-image: url(../img/in-white.png);
    }
    .red_img {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
    }
  }
  .authorization {
    position: absolute;
    top: 13px;
    left: 100%;
    animation: right-left 3s;
    &.log_out {
      top: 37px;
      a.reg {
        display: none;
      }
    }
    @keyframes right-left {
      0%,50% {
        left: 200%;
      }
      100% {
        left: 100%;
      }
    }
    a {
      display: block;
      width: 200px;
      text-align: center;
      font-size: 11px;
      padding: 2px 0;
      border-radius: 20px;
      text-transform: uppercase;
      transition: all 0.3s;
      &.in {
        background: #fff;
        color: #000;
        margin-bottom: 10px;
        border: 1px solid #000;
        &:hover {
          background: #000;
          color: #fff;
        }
      }
      &.reg {
        background: #bc916c;
        color: #fff;
        border: 1px solid #bc916c;
        &:hover {
          background: transparent;
          color: #bc916c;
        }
      }
      &.in_mobile {
        display: none;
        width: 30px;
        height: 30px;
        background-image: url(../img/in-red.png);
        -webkit-background-size: cover;
        background-size: cover;
      }
    }
  }
}

/*------------------------------------------- footer -------------------------------------------*/
footer {
  padding: 50px 0;
  background: #511e1e;
  position: relative;
  .footer_img {
    position: absolute;
    bottom: 99%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {

      a {
        color: #fff;
        transition: all 0.3s;
        &:hover {
          color: #bc916c;
        }
      }
    }
  }
  .bottom_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 90px;
    .language {
      a {
        display: inline-block;
        color: #000;
        width: 30px;
        height: 25px;
        background: #fff;
        border-radius: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 25px;
        font-size: 16px;
        &.active {
          background: #bc916c;
        }
      }
    }
    .agreement {
      color: #fff;
      text-decoration: underline;
      margin-left: 65px;
      font-size: 16px;
    }
    p {
      font-size: 16px;
      color: #fff;
    }
    .social {
      display: flex;
      align-items: center;
      a {
        margin-left: 15px;
        img {
          width: 25px;
        }
      }
    }
  }
}

/*------------------------------------------- btn_1 -------------------------------------------*/
.btn_1 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #fff;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #000;
  transition: all 0.3s;
  &:hover {
    background: #000;
    color: #fff;
  }
}

/*------------------------------------------- btn_2 -------------------------------------------*/
.btn_2 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #fff;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #000;
  transition: all 0.3s;
  border: 1px solid #000;
  &:hover {
    background: #000;
    color: #fff;
  }
}

/*------------------------------------------- btn_3 -------------------------------------------*/
.btn_3 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #bc916c;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s;
  border: 1px solid #bc916c;
  &:hover {
    background: transparent;
    color: #bc916c;
  }
}

/*------------------------------------------- btn_4 -------------------------------------------*/
.btn_4 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #511e1e;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s;
  border: 1px solid #511e1e;
  &:hover {
    background: transparent;
    color: #511e1e;
  }
}

/*------------------------------------------- placeholder -------------------------------------------*/
::-webkit-input-placeholder {font-family: 'Comfortaa'; font-size: 18px;  color: #7f7f7f;}
::-moz-placeholder {font-family: 'Comfortaa'; font-size: 18px;  color: #7f7f7f;}
:-moz-placeholder {font-family: 'Comfortaa'; font-size: 18px;  color: #7f7f7f;}
:-ms-input-placeholder {font-family: 'Comfortaa'; font-size: 18px;  color: #7f7f7f;}
:focus::-webkit-input-placeholder {color: transparent}
:focus::-moz-placeholder          {color: transparent}
:focus:-moz-placeholder           {color: transparent}
:focus:-ms-input-placeholder      {color: transparent}

/*------------------------------------------- form -------------------------------------------*/
form {
  input {
    width: 100%;
    height: 50px;
    border: 1px solid #21201e;
    border-radius: 30px;
    padding: 0 35px;
    font-family: 'Athelas';
    font-size: 20px;
    color: #7f7f7f;
  }
  .select_block {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 22px;
      right: 35px;
      height: 13px;
      width: 13px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      transform: rotate(-45deg);
    }
    select {
      width: 100%;
      height: 50px;
      border: 1px solid #21201e;
      border-radius: 30px;
      padding: 0 70px 0 35px;
      font-family: 'Athelas';
      font-size: 20px;
      color: #7f7f7f;
      appearance: none;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #21201e;
    border-radius: 30px;
    padding: 10px 35px;
  }
  button {
    display: inline-block;
    font-size: 20px;
    font-family: 'Comfortaa';
    background: #bc916c;
    border-radius: 40px;
    width: 100%;
    height: 50px;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.3s;
    border: 1px solid #bc916c;
    cursor: pointer;
    padding: 0 20px;
    &:hover {
      background: transparent;
      color: #bc916c;
    }
  }
  .personal_information_form {
    position: relative;
    display: inline-block;
    input {
      position: absolute;
      left: 0px;
      width: 13px;
      height: 13px;
      z-index: 1;
      top: 6px;
      opacity: 0;
      cursor: pointer;
      &:checked {
        & + span:after {
          content: "";
          position: absolute;
          top: 7px;
          left: 15px;
          width: 10px;
          height: 20px;
          border: none;
          border-bottom: 3px solid #fff;
          border-right: 3px solid #fff;
          transform: rotate(45deg);
        }
      }
    }
    span {
      position: relative;
      padding-left: 50px;
      line-height: 40px;
      color: #000;
      display: block;
      &:before {
        content: "";
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #bc916c;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

/*------------------------------------------- delivery -------------------------------------------*/
header .nav.header_left {
  display: block;
  width: 490px;
  .top {
    border-bottom: 2px solid #bc916c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    .caption {
      color: #bc916c;
      font-size: 17px;
      margin-right: auto;
    }
    .btn_2 {
      padding: 5px 15px;
      font-size: 15px;
      width: 150px;
      text-align: center;
    }
    .btn_3 {
      padding: 5px 15px;
      font-size: 15px;
      width: 150px;
      text-align: center;
      margin-left: 5px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    padding-top: 15px;
    justify-content: space-between;
    .tel {
      font-size: 25px;
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
}
header.active .nav.header_left {
  .bottom {
    .tel {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
}
header .nav.header_right {
  display: block;
  width: 490px;
  .top {
    border-bottom: 2px solid #bc916c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    p {
      margin-left: 5px;
      color: #fff;
    }
    .price {
      font-weight: bold;
      margin-left: 5px;
      color: #fff;
    }
    .btn_3 {
      padding: 5px 15px;
      font-size: 15px;
      width: 150px;
      text-align: center;
    }
    .btn_2 {
      padding: 5px 15px;
      font-size: 15px;
      width: 150px;
      text-align: center;
    }
  }
  .bottom {
    text-align: right;
    padding-top: 15px;
    color: #fff;
  }
}
header.active .nav.header_right {
  .top {
    p {
      color: #fff;
    }
    .price {
      color: #fff;
    }
  }
  .bottom {
    color: #fff;
  }
}
















