@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-Regular.woff2') format('woff2'),
  url('../fonts/Comfortaa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adlery Pro';
  src: url('../fonts/AdleryPro.woff2') format('woff2'),
  url('../fonts/AdleryPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Citrica Cyrillic';
  src: url('../fonts/CitricaCyrillic.woff2') format('woff2'),
  url('../fonts/CitricaCyrillic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Athelas';
  src: url('../fonts/Athelas-Regular.woff2') format('woff2'),
  url('../fonts/Athelas-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Abril Fatface';
  src: url('../fonts/AbrilFatface-Regular.woff2') format('woff2'),
  url('../fonts/AbrilFatface-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

