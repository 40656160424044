@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-Regular.woff2') format('woff2'), url('../fonts/Comfortaa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Adlery Pro';
  src: url('../fonts/AdleryPro.woff2') format('woff2'), url('../fonts/AdleryPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Citrica Cyrillic';
  src: url('../fonts/CitricaCyrillic.woff2') format('woff2'), url('../fonts/CitricaCyrillic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Athelas';
  src: url('../fonts/Athelas-Regular.woff2') format('woff2'), url('../fonts/Athelas-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Abril Fatface';
  src: url('../fonts/AbrilFatface-Regular.woff2') format('woff2'), url('../fonts/AbrilFatface-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: black;
  background: white;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
a:link,
a:visited,
a:active {
  outline: none;
  text-decoration: none;
}
ol,
ul {
  list-style: none;
}
:focus {
  outline: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote,
q {
  quotes: "" "";
}
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  overflow-x: hidden;
}
body {
  font-family: 'Comfortaa';
  color: #000;
  font-size: 18px;
  line-height: 21px;
  position: relative;
}
h1 {
  font-size: 60px;
  line-height: 63px;
  color: #fff;
  font-family: 'Adlery Pro';
}
h1 span {
  display: block;
  font-size: 155px;
}
h2 {
  font-size: 60px;
  line-height: 60px;
  font-family: 'Citrica Cyrillic';
  text-transform: uppercase;
}
h3 {
  color: #bc916c;
  font-size: 45px;
  line-height: 45px;
  font-family: 'Adlery Pro';
}
h4 {
  font-size: 28px;
  line-height: 31px;
}
/*------------------------------------------- flex_2_column -------------------------------------------*/
.flex_2_column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex_2_column > div {
  width: 49%;
}
.flex_2_column > a {
  width: 49%;
}
/*------------------------------------------- flex_3_column -------------------------------------------*/
.flex_3_column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex_3_column > div {
  width: 32%;
}
.flex_3_column > a {
  width: 32%;
}
.flex_3_column > input {
  width: 32%;
}
/*------------------------------------------- flex_4_column -------------------------------------------*/
.flex_4_column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex_4_column > div {
  width: 23%;
}
.flex_4_column > a {
  width: 23%;
}
/*------------------------------------------- main -------------------------------------------*/
main {
  transition: all 0.3s;
}
main.active {
  transform: translateX(300px);
}
/*------------------------------------------- mobile_nav -------------------------------------------*/
.mobile_nav {
  z-index: 101;
  position: fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 1000px;
  background: #21201e;
  border-bottom: 1px solid #fff;
  overflow-x: hidden;
  transition: all 0.3s;
}
.mobile_nav.active {
  left: -300px;
}
.mobile_nav li a {
  display: block;
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid #fff;
}
/*------------------------------------------- nav_container -------------------------------------------*/
.nav_container {
  display: none;
}
.nav_container .nav_icon {
  width: 40px;
  height: 30px;
  position: relative;
  cursor: pointer;
}
.nav_container .nav_icon.open span:nth-of-type(1) {
  top: 13px;
  transform: rotate(45deg);
}
.nav_container .nav_icon.open span:nth-of-type(2) {
  opacity: 0;
}
.nav_container .nav_icon.open span:nth-of-type(3) {
  bottom: 14px;
  transform: rotate(-45deg);
}
.nav_container .nav_icon span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 20%;
  background: #633631;
  transition: all 0.3s ease-in-out;
}
.nav_container .nav_icon span:nth-of-type(1) {
  top: 0;
}
.nav_container .nav_icon span:nth-of-type(2) {
  top: 14px;
}
.nav_container .nav_icon span:nth-of-type(3) {
  bottom: 0;
}
/*------------------------------------------- header -------------------------------------------*/
header {
  top: 0;
  position: fixed;
  height: 135px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  transition: all 0.3s;
}
header.right {
  transform: translateX(300px);
}
header.black_bg {
  background: rgba(0, 0, 0, 0.7);
}
header.active {
  background: #21201e;
}
header.active .authorization a.in_mobile {
  background-image: url(../img/in-white.png);
}
header.active .nav_container .nav_icon span {
  background: #fff;
}
header.active .nav li a {
  color: #fff;
}
header.delivery {
  background: #21201e;
}
header.delivery .nav_container .nav_icon span {
  background: #fff;
}
header.delivery .authorization a.in_mobile {
  background-image: url(../img/in-white.png);
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
header .big_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
header .nav {
  display: flex;
  width: 370px;
  justify-content: space-between;
}
header .nav li a {
  font-size: 15px;
  transition: all 0.3s;
  color: #000;
}
header .logo {
  max-width: 280px;
}
header .logo .static {
  display: block;
}
header .logo .scroll {
  display: none;
}
header .logo.active .static {
  display: none;
}
header .logo.active .scroll {
  display: block;
}
header.white .nav_container .nav_icon span {
  background: #fff;
}
header.white .authorization a.in_mobile {
  background-image: url(../img/in-white.png);
}
header.white .nav {
  display: flex;
}
header.white .nav li a {
  color: #fff;
}
header.white .nav li a:hover {
  color: #bc916c;
}
header.red_bg {
  background: #511e1e;
}
header.red_bg .nav_container .nav_icon span {
  background: #fff;
}
header.red_bg .authorization a.in_mobile {
  background-image: url(../img/in-white.png);
}
header.red_bg .red_img {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}
header .authorization {
  position: absolute;
  top: 13px;
  left: 100%;
  animation: right-left 3s;
}
header .authorization.log_out {
  top: 37px;
}
header .authorization.log_out a.reg {
  display: none;
}
@keyframes right-left {
  0%,
  50% {
    left: 200%;
  }
  100% {
    left: 100%;
  }
}
header .authorization a {
  display: block;
  width: 200px;
  text-align: center;
  font-size: 11px;
  padding: 2px 0;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all 0.3s;
}
header .authorization a.in {
  background: #fff;
  color: #000;
  margin-bottom: 10px;
  border: 1px solid #000;
}
header .authorization a.in:hover {
  background: #000;
  color: #fff;
}
header .authorization a.reg {
  background: #bc916c;
  color: #fff;
  border: 1px solid #bc916c;
}
header .authorization a.reg:hover {
  background: transparent;
  color: #bc916c;
}
header .authorization a.in_mobile {
  display: none;
  width: 30px;
  height: 30px;
  background-image: url(../img/in-red.png);
  -webkit-background-size: cover;
  background-size: cover;
}
/*------------------------------------------- footer -------------------------------------------*/
footer {
  padding: 50px 0;
  background: #511e1e;
  position: relative;
}
footer .footer_img {
  position: absolute;
  bottom: 99%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
}
footer .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .nav li a {
  color: #fff;
  transition: all 0.3s;
}
footer .nav li a:hover {
  color: #bc916c;
}
footer .bottom_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 90px;
}
footer .bottom_block .language a {
  display: inline-block;
  color: #000;
  width: 30px;
  height: 25px;
  background: #fff;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 25px;
  font-size: 16px;
}
footer .bottom_block .language a.active {
  background: #bc916c;
}
footer .bottom_block .agreement {
  color: #fff;
  text-decoration: underline;
  margin-left: 65px;
  font-size: 16px;
}
footer .bottom_block p {
  font-size: 16px;
  color: #fff;
}
footer .bottom_block .social {
  display: flex;
  align-items: center;
}
footer .bottom_block .social a {
  margin-left: 15px;
}
footer .bottom_block .social a img {
  width: 25px;
}
/*------------------------------------------- btn_1 -------------------------------------------*/
.btn_1 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #fff;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #000;
  transition: all 0.3s;
}
.btn_1:hover {
  background: #000;
  color: #fff;
}
/*------------------------------------------- btn_2 -------------------------------------------*/
.btn_2 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #fff;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #000;
  transition: all 0.3s;
  border: 1px solid #000;
}
.btn_2:hover {
  background: #000;
  color: #fff;
}
/*------------------------------------------- btn_3 -------------------------------------------*/
.btn_3 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #bc916c;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s;
  border: 1px solid #bc916c;
}
.btn_3:hover {
  background: transparent;
  color: #bc916c;
}
/*------------------------------------------- btn_4 -------------------------------------------*/
.btn_4 {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #511e1e;
  border-radius: 40px;
  padding: 25px 40px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s;
  border: 1px solid #511e1e;
}
.btn_4:hover {
  background: transparent;
  color: #511e1e;
}
/*------------------------------------------- placeholder -------------------------------------------*/
::-webkit-input-placeholder {
  font-family: 'Comfortaa';
  font-size: 18px;
  color: #7f7f7f;
}
::-moz-placeholder {
  font-family: 'Comfortaa';
  font-size: 18px;
  color: #7f7f7f;
}
:-moz-placeholder {
  font-family: 'Comfortaa';
  font-size: 18px;
  color: #7f7f7f;
}
:-ms-input-placeholder {
  font-family: 'Comfortaa';
  font-size: 18px;
  color: #7f7f7f;
}
:focus::-webkit-input-placeholder {
  color: transparent;
}
:focus::-moz-placeholder {
  color: transparent;
}
:focus:-moz-placeholder {
  color: transparent;
}
:focus:-ms-input-placeholder {
  color: transparent;
}
/*------------------------------------------- form -------------------------------------------*/
form input {
  width: 100%;
  height: 50px;
  border: 1px solid #21201e;
  border-radius: 30px;
  padding: 0 35px;
  font-family: 'Athelas';
  font-size: 20px;
  color: #7f7f7f;
}
form .select_block {
  position: relative;
}
form .select_block:after {
  content: "";
  position: absolute;
  bottom: 22px;
  right: 35px;
  height: 13px;
  width: 13px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(-45deg);
}
form .select_block select {
  width: 100%;
  height: 50px;
  border: 1px solid #21201e;
  border-radius: 30px;
  padding: 0 70px 0 35px;
  font-family: 'Athelas';
  font-size: 20px;
  color: #7f7f7f;
  appearance: none;
}
form textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #21201e;
  border-radius: 30px;
  padding: 10px 35px;
}
form button {
  display: inline-block;
  font-size: 20px;
  font-family: 'Comfortaa';
  background: #bc916c;
  border-radius: 40px;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s;
  border: 1px solid #bc916c;
  cursor: pointer;
  padding: 0 20px;
}
form button:hover {
  background: transparent;
  color: #bc916c;
}
form .personal_information_form {
  position: relative;
  display: inline-block;
}
form .personal_information_form input {
  position: absolute;
  left: 0px;
  width: 13px;
  height: 13px;
  z-index: 1;
  top: 6px;
  opacity: 0;
  cursor: pointer;
}
form .personal_information_form input:checked + span:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 15px;
  width: 10px;
  height: 20px;
  border: none;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
}
form .personal_information_form span {
  position: relative;
  padding-left: 50px;
  line-height: 40px;
  color: #000;
  display: block;
}
form .personal_information_form span:before {
  content: "";
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #bc916c;
  position: absolute;
  top: 0;
  left: 0;
}
/*------------------------------------------- delivery -------------------------------------------*/
header .nav.header_left {
  display: block;
  width: 490px;
}
header .nav.header_left .top {
  border-bottom: 2px solid #bc916c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}
header .nav.header_left .top .caption {
  color: #bc916c;
  font-size: 17px;
  margin-right: auto;
}
header .nav.header_left .top .btn_2 {
  padding: 5px 15px;
  font-size: 15px;
  width: 150px;
  text-align: center;
}
header .nav.header_left .top .btn_3 {
  padding: 5px 15px;
  font-size: 15px;
  width: 150px;
  text-align: center;
  margin-left: 5px;
}
header .nav.header_left .bottom {
  display: flex;
  align-items: center;
  padding-top: 15px;
  justify-content: space-between;
}
header .nav.header_left .bottom .tel {
  font-size: 25px;
  color: #fff;
}
header .nav.header_left .bottom p {
  color: #fff;
}
header.active .nav.header_left .bottom .tel {
  color: #fff;
}
header.active .nav.header_left .bottom p {
  color: #fff;
}
header .nav.header_right {
  display: block;
  width: 490px;
}
header .nav.header_right .top {
  border-bottom: 2px solid #bc916c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}
header .nav.header_right .top p {
  margin-left: 5px;
  color: #fff;
}
header .nav.header_right .top .price {
  font-weight: bold;
  margin-left: 5px;
  color: #fff;
}
header .nav.header_right .top .btn_3 {
  padding: 5px 15px;
  font-size: 15px;
  width: 150px;
  text-align: center;
}
header .nav.header_right .top .btn_2 {
  padding: 5px 15px;
  font-size: 15px;
  width: 150px;
  text-align: center;
}
header .nav.header_right .bottom {
  text-align: right;
  padding-top: 15px;
  color: #fff;
}
header.active .nav.header_right .top p {
  color: #fff;
}
header.active .nav.header_right .top .price {
  color: #fff;
}
header.active .nav.header_right .bottom {
  color: #fff;
}
/*------------------------------------------- main_banner -------------------------------------------*/
.main_banner {
  -webkit-background-size: cover;
  background-size: cover;
  padding: 100px 0;
}
.main_banner.developments {
  background-image: url(../img/developments-main.png);
  padding-top: 235px;
  padding-bottom: 150px;
  text-align: center;
}
.main_banner.main {
  background-image: url(../img/main_bg.jpg);
  margin-top: 135px;
  text-align: center;
}
.main_banner.main h1 {
  font-size: 90px;
  line-height: 97px;
}
.main_banner.main h4 {
  margin-top: 50px;
  color: #fff;
}
.main_banner.contacts {
  background-image: url(../img/contact-bg.jpg);
  padding-bottom: 550px;
}
.main_banner.contacts .divider_container .text h2 {
  color: #fff;
}
.main_banner.contacts .divider_container .text h3 {
  color: #fff;
}
.main_banner.restaurant {
  background-image: url(../img/restaurant_bg.jpg);
  margin-top: 135px;
  text-align: center;
}
.main_banner.restaurant h1 {
  font-size: 100px;
  line-height: 100px;
}
.main_banner.restaurant h1 span {
  font-size: 138px;
}
.main_banner.restaurant .select {
  display: inline-block;
  margin-top: 125px;
}
.main_banner.restaurant .select .text_menu {
  background: #fff;
  border: 2px solid #fff;
}
.main_banner.restaurant .select .text_menu:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 20px;
  width: 18px;
  height: 26px;
  background-image: url(../img/place.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.main_banner.review {
  background-image: url(../img/review-bg.jpg);
  padding-top: 235px;
  padding-bottom: 150px;
  text-align: center;
}
.main_banner.franchising {
  background-image: url(../img/franchising-bg.jpg);
  padding-bottom: 500px;
}
.main_banner.franchising .divider_container .text h2 {
  color: #fff;
}
.main_banner.franchising .divider_container .text h3 {
  color: #fff;
}
.main_banner.agreement {
  background-image: url(../img/agreement-bg.jpg);
  padding-top: 235px;
  padding-bottom: 150px;
  text-align: center;
}
.main_banner.registration {
  background-image: url(../img/registration-bg.jpg);
  padding-top: 235px;
  padding-bottom: 150px;
  text-align: center;
}
.main_banner.password {
  background-image: url(../img/password-bg.jpg);
  padding-top: 235px;
  padding-bottom: 150px;
  text-align: center;
}
.main_banner.one_restaurant {
  display: flex;
  justify-content: space-between;
  height: 800px;
  padding: 0;
}
.main_banner.one_restaurant .left_bg {
  width: 50%;
  height: 100%;
  background-image: url(../img/rest-1.jpg);
  -webkit-background-size: cover;
  background-size: cover;
}
.main_banner.one_restaurant .right_bg {
  width: 50%;
  height: 100%;
  background-image: url(../img/rest-2.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
}
.main_banner.one_restaurant .right_bg .white_bg {
  margin-top: 100px;
  background: #fff;
  padding: 75px 50px;
  display: inline-block;
  margin-left: 125px;
  text-align: center;
  width: 450px;
  position: relative;
}
.main_banner.one_restaurant .right_bg .white_bg:before {
  content: "";
  display: block;
  border: 20px solid #fff;
  position: absolute;
  top: -30px;
  bottom: -30px;
  left: -30px;
  right: -30px;
}
.main_banner.one_restaurant .right_bg .white_bg .place {
  position: relative;
  padding-left: 30px;
  font-weight: bold;
}
.main_banner.one_restaurant .right_bg .white_bg .place span {
  display: block;
  color: #6e6e6e;
  font-size: 15px;
}
.main_banner.one_restaurant .right_bg .white_bg .place:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 17px;
  height: 23px;
  background-image: url(../img/place.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.main_banner.one_restaurant .right_bg .white_bg .metro {
  position: relative;
  padding-left: 30px;
  font-weight: bold;
}
.main_banner.one_restaurant .right_bg .white_bg .metro:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 18px;
  background-image: url(../img/metro.png);
  background-size: cover;
}
.main_banner.one_restaurant .right_bg .white_bg .tel {
  position: relative;
  padding-left: 30px;
  margin-top: 50px;
  font-weight: bold;
}
.main_banner.one_restaurant .right_bg .white_bg .tel:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-image: url(../img/tel.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.main_banner.one_restaurant .right_bg .white_bg .date {
  position: relative;
  padding-left: 30px;
  margin-top: 50px;
  font-weight: bold;
}
.main_banner.one_restaurant .right_bg .white_bg .date:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 23px;
  height: 23px;
  background-image: url(../img/date.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.main_banner.personal_area {
  background-image: url(../img/personal.jpg);
  padding-top: 235px;
  padding-bottom: 150px;
}
.main_banner.personal_area .login {
  width: 500px;
  background: #fff;
  padding: 70px;
  text-align: center;
}
.main_banner.personal_area .login h4 {
  text-transform: uppercase;
}
.main_banner.personal_area .login input {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  margin-top: 20px;
  padding: 0 10px;
}
.main_banner.personal_area .login .btn_3 {
  width: 100%;
  margin-top: 40px;
}
.main_banner.personal_area .login .btn_4 {
  width: 100%;
  margin-top: 20px;
}
.main_banner.personal_area .login .forgot {
  display: inline-block;
  margin-top: 40px;
  color: #000;
  text-decoration: underline;
}
/*------------------------------------------- divider_container -------------------------------------------*/
.divider_container {
  margin: 100px 0;
  text-align: center;
}
.divider_container.no_icons .container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider_container .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divider_container .text {
  text-align: center;
  margin: 0 30px;
}
.divider_container .text img {
  margin-top: 30px;
}
/*------------------------------------------- select -------------------------------------------*/
.select {
  position: relative;
}
.select .text_menu {
  width: 300px;
  border: 2px solid #000;
  border-radius: 30px;
  padding: 10px 40px 10px 30px;
  cursor: pointer;
  position: relative;
}
.select .text_menu.open:after {
  content: "";
  top: 16px;
  right: 27px;
  transform: rotate(-225deg);
}
.select .text_menu:after {
  content: "";
  position: absolute;
  top: 10px;
  right: 27px;
  height: 13px;
  width: 13px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  transition: all 0.3s;
}
.select .drop_menu {
  position: absolute;
  top: 45px;
  left: 18px;
  right: 18px;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  display: none;
  z-index: 10;
  background: #fff;
}
.select .drop_menu li {
  padding: 10px 10px 10px 13px;
  cursor: pointer;
  transition: all 0.3s;
}
.select .drop_menu li:hover {
  background: #bc916c;
  color: #fff;
}
/*------------------------------------------- filter_container -------------------------------------------*/
.filter_container .text_block {
  text-align: center;
  margin: 100px 0 50px 0;
}
.filter_container .address_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter_container .address_block h4 {
  text-transform: uppercase;
  margin-right: 60px;
}
.filter_container .address_block .date {
  color: #cc936b;
  text-transform: uppercase;
  margin-left: auto;
}
.filter_container .calendar_block {
  margin: 60px 0;
  border-bottom: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  position: relative;
}
.filter_container .calendar_block:before {
  content: "";
  position: absolute;
  bottom: 27px;
  left: 45px;
  height: 13px;
  width: 13px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(45deg);
  cursor: pointer;
}
.filter_container .calendar_block:after {
  content: "";
  position: absolute;
  bottom: 27px;
  right: 45px;
  height: 13px;
  width: 13px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-135deg);
  cursor: pointer;
}
.filter_container .calendar_block li {
  text-align: center;
  cursor: pointer;
}
.filter_container .calendar_block li.active {
  color: #cc936b;
}
.filter_container .calendar_block li .week {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.filter_container .calendar_block li .day {
  font-weight: bold;
  display: block;
  margin-bottom: 20px;
}
.filter_container .filter_mobile {
  display: none;
  width: 300px;
  border: 2px solid #000;
  border-radius: 30px;
  padding: 10px 40px 10px 30px;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
}
.filter_container .filter_mobile.open:after {
  content: "";
  top: 16px;
  right: 27px;
  transform: rotate(-225deg);
}
.filter_container .filter_mobile:after {
  content: "";
  position: absolute;
  top: 10px;
  right: 27px;
  height: 13px;
  width: 13px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  transition: all 0.3s;
}
.filter_container .filter_btn_block {
  display: flex;
  flex-wrap: wrap;
}
.filter_container .filter_btn_block li {
  margin: 0 20px 20px 0;
  border: 2px solid #000;
  border-radius: 30px;
  padding: 10px 30px;
  cursor: pointer;
  transition: all 0.3s;
}
.filter_container .filter_btn_block li.active {
  border: 2px solid #cc936b;
  background: #cc936b;
  color: #fff;
}
/*------------------------------------------- developments_container -------------------------------------------*/
.developments_container .developments_item {
  margin-top: 60px;
}
.developments_container .developments_item .img_container {
  height: 360px;
  overflow: hidden;
}
.developments_container .developments_item .img_container img {
  max-width: none;
  height: 100%;
}
.developments_container .developments_item .caption {
  font-weight: bold;
  font-size: 20px;
  margin-top: 25px;
  color: #000;
}
.developments_container .developments_item .place {
  font-family: 'Athelas';
  margin-top: 15px;
  position: relative;
  padding-left: 30px;
  color: #000;
}
.developments_container .developments_item .place:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 17px;
  height: 23px;
  background-image: url(../img/place.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.developments_container .developments_item .time {
  color: #000;
  font-family: 'Athelas';
  margin-top: 15px;
  position: relative;
  padding-left: 30px;
}
.developments_container .developments_item .time:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 19px;
  height: 19px;
  background-image: url(../img/time.png);
  -webkit-background-size: cover;
  background-size: cover;
}
/*------------------------------------------- more_btn -------------------------------------------*/
.more_btn {
  text-align: center;
  margin: 100px 0;
}
.more_btn .more {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  background: #cc936b;
  padding: 30px 50px;
  border-radius: 40px;
  border: 1px solid #bc916c;
  transition: all 0.3s;
}
.more_btn .more:hover {
  background: transparent;
  color: #bc916c;
}
/*------------------------------------------- up_btn -------------------------------------------*/
.up_btn {
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 70px;
  height: 70px;
  background: #bc916c;
  border-radius: 50%;
  z-index: 100;
}
.up_btn:before {
  content: "";
  position: absolute;
  top: 26px;
  left: 20px;
  width: 30px;
  height: 30px;
  border-left: 4px solid #fff;
  border-top: 4px solid #fff;
  transform: rotate(45deg);
}
/*------------------------------------------- menu_container -------------------------------------------*/
.main_menu_container .menu_item {
  text-align: center;
}
.main_menu_container .menu_item .img_block {
  height: 240px;
  display: block;
}
.main_menu_container .menu_item h4 {
  color: #000;
  margin-top: 40px;
  font-family: 'Athelas';
}
.main_menu_container .menu_item a {
  font-size: 23px;
  line-height: 23px;
  text-transform: uppercase;
  color: #000;
}
/*------------------------------------------- stock_1 -------------------------------------------*/
.stock_1 {
  height: 570px;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.stock_1 .bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.stock_1 .dark_bg {
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  position: relative;
}
.stock_1 .dark_bg h4 {
  color: #fff;
}
.stock_1 .dark_bg p {
  color: #fff;
  margin-top: 30px;
}
/*------------------------------------------- stock_2 -------------------------------------------*/
.stock_2 {
  margin-top: 100px;
  position: relative;
}
.stock_2 img {
  width: 100%;
}
.stock_2 .stock_2_container {
  text-align: center;
  position: absolute;
  top: 70px;
  right: 70px;
  left: 70px;
  bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.stock_2 .stock_2_container .divider_container {
  margin: 0 0 50px 0;
}
.stock_2 .stock_2_container .divider_container .text {
  color: #fff;
}
.stock_2 .stock_2_container h4 {
  color: #fff;
  font-family: 'Athelas';
}
.stock_2 .stock_2_container .btn_1 {
  margin-top: auto;
}
/*------------------------------------------- photo_container -------------------------------------------*/
.photo_container .photo_item {
  margin-bottom: 30px;
  height: 360px;
  padding: 10px;
  position: relative;
}
.photo_container .photo_item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -10px;
  left: 0;
  border: 2px solid #bd926c;
  display: block;
}
.photo_container .btn_container {
  margin-top: 40px;
  text-align: center;
}
/*------------------------------------------- video_container -------------------------------------------*/
.video_container {
  margin-bottom: 200px;
}
.video_container .video_item {
  margin-bottom: 30px;
  height: 360px;
  position: relative;
  padding: 10px;
}
.video_container .video_item:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -10px;
  left: 0;
  border: 2px solid #bd926c;
  display: block;
}
.video_container .video_item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  background-image: url(../img/youtube.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.video_container .btn_container {
  margin-top: 40px;
  text-align: center;
}
/*------------------------------------------- report_container -------------------------------------------*/
.report_container.video .report_item .img_block {
  overflow: hidden;
  height: 335px;
  display: block;
  position: relative;
}
.report_container.video .report_item .img_block:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  background-image: url(../img/youtube.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.report_container.video .report_item .img_block img {
  width: 100%;
}
.report_container .report_item {
  text-align: center;
  margin-bottom: 80px;
}
.report_container .report_item .img_block {
  overflow: hidden;
  height: 235px;
  display: block;
}
.report_container .report_item h4 {
  margin-top: 20px;
  color: #000;
}
.report_container .report_item p {
  margin-top: 20px;
  color: #000;
}
/*------------------------------------------- pagination -------------------------------------------*/
.pagination {
  margin-bottom: 200px;
}
.pagination .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination .container a {
  color: #000;
  font-size: 36px;
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
}
.pagination .container a.active {
  color: #fff;
  background: #bc916c;
}
/*------------------------------------------- description_block -------------------------------------------*/
.description_block {
  text-align: center;
  position: relative;
  z-index: 10;
}
.description_block h4 {
  color: #bc916c;
}
.description_block p {
  margin: 50px 0;
}
/*------------------------------------------- gallery -------------------------------------------*/
.gallery {
  margin-bottom: 200px;
}
.gallery .gallery_item {
  margin-bottom: 15px;
  overflow: hidden;
}
/*------------------------------------------- one_video -------------------------------------------*/
.one_video {
  margin-bottom: 50px;
  position: relative;
  z-index: 10;
}
.one_video:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  height: 100%;
  background: #e8e8e8;
}
.one_video .container {
  position: relative;
  z-index: 10;
}
.one_video iframe {
  width: 100%;
  height: 685px;
}
/*------------------------------------------- description_block_2 -------------------------------------------*/
.description_block_2 {
  text-align: center;
  margin-bottom: 200px;
  position: relative;
  z-index: 10;
}
.description_block_2 p {
  margin-top: 20px;
}
/*------------------------------------------- one_event -------------------------------------------*/
.one_event {
  position: relative;
  overflow: hidden;
  margin-top: 135px;
  padding-bottom: 200px;
}
.one_event .left_arrow {
  position: absolute;
  top: 40%;
  left: -100px;
  width: 70px;
  height: 70px;
  background: #bc916c;
  border-radius: 50%;
  z-index: 11;
}
.one_event .left_arrow:before {
  content: "";
  position: absolute;
  top: 19px;
  left: 27px;
  width: 30px;
  height: 30px;
  border-left: 4px solid #fff;
  border-top: 4px solid #fff;
  transform: rotate(-45deg);
}
.one_event .right_arrow {
  position: absolute;
  top: 40%;
  right: -100px;
  width: 70px;
  height: 70px;
  background: #bc916c;
  border-radius: 50%;
  z-index: 10;
}
.one_event .right_arrow:before {
  content: "";
  position: absolute;
  top: 19px;
  left: 16px;
  width: 30px;
  height: 30px;
  border-left: 4px solid #fff;
  border-top: 4px solid #fff;
  transform: rotate(135deg);
}
.one_event .container {
  position: relative;
}
.one_event .img_block {
  position: relative;
  z-index: 10;
  height: 600px;
  overflow: hidden;
  margin-bottom: 50px;
}
.one_event:before {
  content: "";
  position: absolute;
  top: 360px;
  left: 0;
  right: 0;
  height: 100%;
  background: #e8e8e8;
}
/*------------------------------------------- about_description -------------------------------------------*/
.about_description {
  margin-top: 240px;
  text-align: center;
}
.about_description h4 {
  font-family: 'Athelas';
  margin-top: 10px;
}
/*------------------------------------------- number_block -------------------------------------------*/
.number_block {
  background-image: url(../img/number-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  padding: 70px 0;
  margin-top: 100px;
}
.number_block .white_bg {
  background: #fff;
  padding: 60px;
}
.number_block .white_bg .divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.number_block .white_bg .divider .text {
  text-align: center;
  margin: 0 30px;
}
.number_block .white_bg .divider .text h2 {
  font-weight: bold;
}
.number_block .white_bg .divider .text img {
  margin-top: 30px;
}
.number_block .white_bg .numbers {
  display: flex;
  justify-content: space-between;
}
.number_block .white_bg .numbers .number_item {
  margin-top: 100px;
  width: 17%;
  text-align: center;
}
.number_block .white_bg .numbers .number_item .num {
  font-size: 110px;
  font-family: 'Abril Fatface';
  line-height: 110px;
  font-weight: bold;
}
.number_block .white_bg .numbers .number_item h4 {
  font-size: 45px;
  line-height: 45px;
  font-family: 'Athelas';
}
.number_block .white_bg .numbers .number_item p {
  font-family: 'Athelas';
  margin-top: 10px;
}
/*------------------------------------------- look_block -------------------------------------------*/
.look_block {
  margin: 100px 0;
}
.look_block .btn_block {
  margin-top: 35px;
  text-align: center;
}
.look_block .mt80 {
  margin-top: 80px;
}
.look_block .item {
  height: 500px;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
}
.look_block .item:hover .text {
  height: 200px;
}
.look_block .item .text {
  transition: all 0.3s;
  position: absolute;
  height: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.look_block .item .text .sub_text {
  color: #fff;
  text-transform: uppercase;
  padding-right: 35px;
  position: relative;
}
.look_block .item .text .sub_text:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 0;
  height: 12px;
  width: 12px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
}
/*------------------------------------------- rewards_container -------------------------------------------*/
.rewards_container {
  text-align: center;
  padding: 100px 0;
  background-image: url(../img/rewards-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
}
.rewards_container .divider_container {
  margin-top: 0;
}
.rewards_container .divider_container .text h3 {
  color: #fff;
}
.rewards_container .divider_container .text h2 {
  color: #fff;
}
.rewards_container .btn_1 {
  margin-top: 50px;
}
/*------------------------------------------- partner_container -------------------------------------------*/
.partner_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.partner_container img {
  filter: grayscale(100%);
}
.partner_container img:hover {
  filter: grayscale(0);
}
/*------------------------------------------- franchise_container -------------------------------------------*/
.franchise_container {
  text-align: center;
}
.franchise_container h4 {
  font-family: 'Athelas';
}
.franchise_container .franchise_block {
  margin-top: 50px;
}
.franchise_container .franchise_block .franchise_item .img_container {
  height: 360px;
  overflow: hidden;
}
.franchise_container .franchise_block .franchise_item .img_container img {
  max-width: none;
}
.franchise_container .franchise_block .franchise_item h4 {
  margin-top: 30px;
}
.franchise_container .franchise_block .franchise_item .btn_3 {
  margin-top: 30px;
}
/*------------------------------------------- application_container -------------------------------------------*/
.application_container {
  padding: 70px 0;
  background: #511e1e;
  margin-top: 100px;
  text-align: center;
}
.application_container h4 {
  color: #fff;
  font-size: 35px;
  line-height: 38px;
}
.application_container .btn_3 {
  margin-top: 30px;
}
/*------------------------------------------- command_block -------------------------------------------*/
.command_block {
  text-align: center;
  margin: 100px 0;
}
.command_block h4 {
  font-size: 45px;
  line-height: 48px;
}
.command_block img {
  display: block;
  margin: 50px 0;
}
/*------------------------------------------- all_contacts -------------------------------------------*/
.all_contacts {
  background: #f2f2f2;
  padding-bottom: 100px;
}
.all_contacts form {
  padding: 100px;
  background: #fff;
  text-align: center;
  position: relative;
  top: -500px;
}
.all_contacts form input {
  margin-top: 40px;
}
.all_contacts form select {
  margin-top: 40px;
}
.all_contacts form textarea {
  margin-top: 40px;
}
.all_contacts form .capt {
  align-items: center;
  margin-top: 20px;
}
.all_contacts form .capt input {
  margin-top: 0;
}
.all_contacts form .submit_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.all_contacts form .submit_block > div {
  width: 49%;
}
.all_contacts form .submit_block p {
  font-size: 14px;
  line-height: 17px;
  font-family: 'Athelas';
  color: #000;
}
.all_contacts form .submit_block p a {
  color: #000;
  text-decoration: underline;
}
.all_contacts .contacts {
  margin-top: -470px;
}
.all_contacts .contacts .contact_item {
  margin-top: 80px;
}
.all_contacts .contacts .contact_item p {
  margin-top: 25px;
}
.all_contacts .contacts .contact_item p .name {
  color: #bc916c;
}
.all_contacts .contacts .contact_item .tel {
  margin-top: 25px;
  padding-left: 40px;
  position: relative;
}
.all_contacts .contacts .contact_item .tel:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-image: url(../img/tel.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.all_contacts .contacts .contact_item .mail {
  margin-top: 25px;
  padding-left: 40px;
  position: relative;
}
.all_contacts .contacts .contact_item .mail:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background-image: url(../img/mail.png);
  -webkit-background-size: cover;
  background-size: cover;
}
/*------------------------------------------- personnel_container -------------------------------------------*/
.personnel_container .personnel_item {
  margin-bottom: 100px;
  text-align: center;
}
.personnel_container .personnel_item .img_container {
  height: 350px;
  overflow: hidden;
}
.personnel_container .personnel_item .img_container img {
  max-width: none;
  height: 100%;
}
.personnel_container .personnel_item h4 {
  margin-top: 30px;
  font-weight: bold;
}
.personnel_container .personnel_item p {
  margin-top: 20px;
  font-family: 'Athelas';
}
.personnel_container .personnel_item .icon_block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.personnel_container .personnel_item .icon_block .tel {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-image: url(../img/personnel-tel.png);
  margin: 0 10px;
}
.personnel_container .personnel_item .icon_block .mail {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-image: url(../img/personnel-mail.png);
  margin: 0 10px;
}
/*------------------------------------------- map_filter -------------------------------------------*/
.map_filter {
  margin: 100px 0;
}
.map_filter .flex_3_column {
  align-items: center;
}
.map_filter .select .text_menu {
  width: 100%;
}
.map_filter .review {
  text-align: right;
  color: #511e1e;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  padding-right: 35px;
}
.map_filter .review:after {
  content: "";
  position: absolute;
  top: 1px;
  right: 0;
  width: 15px;
  height: 15px;
  border-top: 2px solid #511e1e;
  border-right: 2px solid #511e1e;
  transform: rotate(45deg);
}
/*------------------------------------------- map -------------------------------------------*/
.map iframe {
  width: 100%;
  height: 500px;
}
/*------------------------------------------- place_container -------------------------------------------*/
.place_container {
  margin-bottom: 200px;
}
.place_container .place_item {
  margin-top: 70px;
}
.place_container .place_item .img_container {
  height: 240px;
  overflow: hidden;
  padding: 10px;
  position: relative;
}
.place_container .place_item .img_container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #bd926c;
  display: block;
}
.place_container .place_item .img_container img {
  height: 100%;
  max-width: none;
}
.place_container .place_item .place {
  font-family: 'Athelas';
  margin-top: 15px;
  position: relative;
  padding-left: 30px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.place_container .place_item .place:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 17px;
  height: 23px;
  background-image: url(../img/place.png);
  -webkit-background-size: cover;
  background-size: cover;
}
.place_container .place_item .metro {
  font-family: 'Athelas';
  margin-top: 15px;
  color: #511e1e;
  font-size: 20px;
  font-weight: bold;
}
/*------------------------------------------- review_container -------------------------------------------*/
.review_container {
  background: #f2f2f2;
}
.review_container .give_feedback {
  padding: 70px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review_container .give_feedback p {
  width: 70%;
}
.review_container .review_block .review_item {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 70px;
}
.review_container .review_block .review_item.red {
  border: 1px solid #960014;
}
.review_container .review_block .review_item.green {
  border: 1px solid #18a659;
}
.review_container .review_block .review_item.yellow {
  border: 1px solid #f8f400;
}
.review_container .review_block .review_item .left {
  width: 80%;
}
.review_container .review_block .review_item .left p {
  margin: 20px 0;
  font-family: 'Athelas';
  font-size: 20px;
}
.review_container .review_block .review_item .left .place {
  font-family: 'Athelas';
  position: relative;
  padding-left: 30px;
  color: #bc916c;
}
.review_container .review_block .review_item .left .place:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 17px;
  height: 23px;
  background-image: url(../img/place.png);
  background-size: cover;
}
.review_container .review_block .review_item .right {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.review_container .review_block .review_item .right .date {
  text-align: right;
  color: #bc916c;
  font-family: 'Athelas';
  font-size: 20px;
}
.review_container .review_block .review_item .right .stars {
  display: flex;
  justify-content: flex-end;
}
.review_container .review_block .review_item .right .stars li {
  margin: 0 5px;
  width: 27px;
  height: 25px;
  background-image: url(../img/star.png);
}
.review_container .review_block .review_item .right .stars li.active {
  background-image: url(../img/star-active.png);
}
.review_container .btn_container {
  margin-top: 100px;
  padding-bottom: 150px;
  text-align: center;
}
/*------------------------------------------- form_communication -------------------------------------------*/
.form_communication {
  background: #f2f2f2;
}
.form_communication .form {
  position: relative;
  top: -500px;
  padding: 100px;
  background: #fff;
  text-align: center;
}
.form_communication .form h3 {
  font-family: 'Comfortaa';
  color: #000;
}
.form_communication .form .brown_border {
  margin: 50px 0;
  border: 4px solid #bc916c;
  display: inline-block;
  padding: 10px 90px 40px 90px;
  border-radius: 50px;
  text-align: left;
}
.form_communication .form .brown_border h4 {
  margin-top: 30px;
}
.form_communication .form .brown_border .tel {
  position: relative;
  padding-left: 70px;
}
.form_communication .form .brown_border .tel:before {
  content: "";
  position: absolute;
  top: -13px;
  left: 0;
  background-image: url(../img/personnel-tel.png);
  -webkit-background-size: cover;
  background-size: cover;
  height: 50px;
  width: 50px;
  display: inline-block;
}
.form_communication .form .brown_border .mail {
  position: relative;
  padding-left: 70px;
}
.form_communication .form .brown_border .mail:before {
  content: "";
  position: absolute;
  top: -13px;
  left: 0;
  background-image: url(../img/personnel-mail.png);
  -webkit-background-size: cover;
  background-size: cover;
  height: 50px;
  width: 50px;
  display: inline-block;
}
/*------------------------------------------- advantages_container -------------------------------------------*/
.advantages_container {
  margin-top: -210px;
  background-image: url(../img/advantages-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
}
.advantages_container .container {
  padding: 205px 0;
  position: relative;
}
.advantages_container .container .advantages_item {
  padding: 10px;
  text-align: center;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
}
.advantages_container .container .advantages_item:nth-of-type(1) {
  position: absolute;
  top: -40%;
  left: 0;
}
.advantages_container .container .advantages_item:nth-of-type(2) {
  position: absolute;
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
}
.advantages_container .container .advantages_item:nth-of-type(3) {
  position: absolute;
  top: -40%;
  right: 0;
}
.advantages_container .container .advantages_item:nth-of-type(4) {
  position: absolute;
  bottom: -40%;
  left: 0;
}
.advantages_container .container .advantages_item:nth-of-type(5) {
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translateX(-50%);
}
.advantages_container .container .advantages_item:nth-of-type(6) {
  position: absolute;
  bottom: -40%;
  right: 0;
}
.advantages_container .container .advantages_item .number {
  font-family: 'Abril Fatface';
  font-size: 60px;
  line-height: 60px;
  color: #511e1e;
  margin-bottom: 25px;
}
/*------------------------------------------- pride -------------------------------------------*/
.pride {
  text-align: center;
  background: #f2f2f2;
  padding: 290px 0 165px 0;
}
/*------------------------------------------- conditions_container -------------------------------------------*/
.conditions_container {
  border-bottom: 2px solid #bc916c;
}
.conditions_container .conditions_item {
  border-top: 2px solid #bc916c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.conditions_container .conditions_item h4 {
  width: 50%;
}
/*------------------------------------------- additional_info -------------------------------------------*/
.additional_info {
  position: relative;
}
.additional_info .bg_block {
  height: 600px;
  background-image: url(../img/additional-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}
.additional_info .grey_border {
  position: relative;
  z-index: 10;
  background: #fff;
  border: 10px solid #aeaeae;
  padding: 0 60px 60px 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.additional_info .grey_border .item {
  width: 24%;
  text-align: center;
  margin: 70px 40px 0 40px;
}
.additional_info .grey_border .item img {
  height: 160px;
}
.additional_info .grey_border .item p {
  margin-bottom: 25px;
}
.additional_info .grey_border .item h4 {
  margin-top: 50px;
}
/*------------------------------------------- stages_container -------------------------------------------*/
.stages_container {
  background: #e8e8e8;
  padding: 100px 0 50px 0;
}
.stages_container .stages_block {
  display: flex;
  justify-content: space-between;
}
.stages_container .white_bg {
  background: #fff;
  border-radius: 30px;
  padding: 40px;
  width: 35%;
}
.stages_container .white_bg .caption {
  color: #bc916c;
  margin-top: 30px;
}
.stages_container .white_bg .divider {
  margin-top: 30px;
  background: #bc916c;
  height: 2px;
}
.stages_container .step_by_step {
  width: 55%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.stages_container .step_by_step .step {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 10px;
  position: relative;
}
.stages_container .step_by_step .step:after {
  content: "";
  position: absolute;
  height: 40px;
  width: 3px;
  background: #bc916c;
  display: inline-block;
  top: 147px;
  left: 50%;
  transform: translateX(-50%);
}
.stages_container .step_by_step .step:nth-of-type(2):before {
  content: "";
  position: absolute;
  top: -37px;
  left: 116px;
  width: 132px;
  height: 39px;
  background-color: transparent;
  background-image: url(../img/arc-2.png);
}
.stages_container .step_by_step .step:nth-of-type(7):after {
  width: 132px;
  height: 39px;
  background-color: transparent;
  background-image: url(../img/arc-1.png);
  left: 130%;
}
.stages_container .step_by_step .step:nth-of-type(8):after {
  display: none;
}
.stages_container .step_by_step .step:nth-of-type(9):after {
  display: none;
}
.stages_container .step_by_step .step p {
  font-size: 16px;
  font-family: 'Athelas';
  margin-top: 5px;
  text-align: center;
}
/*------------------------------------------- our_advantages -------------------------------------------*/
.our_advantages .item {
  margin-top: 30px;
}
.our_advantages .item h4 {
  color: #bc916c;
  padding-left: 70px;
}
.our_advantages .item p {
  font-size: 24px;
  line-height: 27px;
}
/*------------------------------------------- receipts -------------------------------------------*/
.receipts {
  margin: 100px 0 200px 0;
  text-align: center;
}
.receipts h2 {
  color: #bc916c;
  font-size: 130px;
  font-family: 'Comfortaa';
  line-height: 130px;
  margin-bottom: 40px;
}
/*------------------------------------------- text_container -------------------------------------------*/
.text_container {
  margin: 100px 0 200px 0;
}
.text_container p {
  font-family: 'Athelas';
  margin-top: 25px;
}
.text_container h4 {
  color: #bc916c;
  font-size: 24px;
  line-height: 27px;
  margin-top: 25px;
}
.text_container ol {
  list-style: decimal inside;
}
.text_container ol li {
  font-family: 'Athelas';
  font-size: 18px;
  padding-left: 35px;
}
/*------------------------------------------- registration_container -------------------------------------------*/
.registration_container {
  margin-bottom: 200px;
}
.registration_container a {
  color: #bc916c;
}
.registration_container .main_text {
  text-align: center;
  margin-top: 30px;
}
.registration_container button {
  margin: 30px auto 0 auto;
  width: 310px;
  display: block;
}
.registration_container .registration_block {
  margin-top: 50px;
}
.registration_container .registration_block .item {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registration_container .registration_block .item .w65 {
  width: 65px;
}
.registration_container .registration_block .item .w65 input {
  padding: 0 28px;
}
.registration_container .registration_block .item .w100 {
  width: 100px;
}
.registration_container .registration_block .item .w250 {
  width: 250px;
}
.registration_container .registration_block .item .caption {
  width: 30%;
  font-size: 28px;
  line-height: 30px;
}
.registration_container .registration_block .item .field {
  width: 60%;
}
.registration_container .registration_block .item .field.flex_block {
  display: flex;
  align-items: center;
}
.registration_container .registration_block .item .field.flex_block > div {
  margin-right: 10px;
}
/*------------------------------------------- password_container -------------------------------------------*/
.password_container {
  margin: 100px 0 200px 0;
}
.password_container h4 {
  font-family: 'Athelas';
  font-size: 24px;
  text-align: center;
}
.password_container .password_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.password_container .password_flex > div {
  margin: 0 10px;
}
/*------------------------------------------- restaurant_icons -------------------------------------------*/
.restaurant_icons {
  margin-top: 40px;
}
.restaurant_icons .item {
  display: flex;
  align-items: center;
  margin-top: 60px;
}
.restaurant_icons .item .img_container {
  width: 80px;
  margin-right: 50px;
}
.restaurant_icons .item .text {
  font-size: 25px;
  line-height: 27px;
}
/*------------------------------------------- new_event -------------------------------------------*/
.new_event .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
}
.new_event .item .img_block {
  overflow: hidden;
  height: 335px;
}
.new_event .item .img_block img {
  height: 100%;
  max-width: none;
}
.new_event .item .caption {
  font-size: 23px;
  margin: 25px 0;
}
.new_event .item p {
  margin-top: auto;
}
/*------------------------------------------- restaurant_review -------------------------------------------*/
.restaurant_review {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.restaurant_review .review {
  margin-top: 50px;
}
.restaurant_review .review .info {
  margin-top: 15px;
}
.restaurant_review .review .text {
  margin-top: 15px;
  font-size: 22px;
  line-height: 25px;
}
/*------------------------------------------- review_btns -------------------------------------------*/
.review_btns {
  margin: 70px auto 0 auto;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/*------------------------------------------- one_human_container -------------------------------------------*/
.one_human_container {
  position: relative;
  margin-top: 230px;
  background-color: #f2f2f2;
  text-align: center;
  padding-bottom: 200px;
}
.one_human_container .bg_human {
  background-image: url(../img/bg-human.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  height: 370px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.one_human_container .one_human {
  position: relative;
  z-index: 10;
}
.one_human_container .one_human .btn_3 {
  margin-top: 70px;
}
.one_human_container .one_human .white_bg {
  margin: 0 auto;
  background: #fff;
  padding: 0 30px 30px 30px;
  text-align: center;
  width: 450px;
  position: relative;
}
.one_human_container .one_human .white_bg p {
  margin-top: 5px;
}
.one_human_container .one_human .white_bg:before {
  content: "";
  display: block;
  border: 20px solid #fff;
  position: absolute;
  top: -30px;
  bottom: -30px;
  left: -30px;
  right: -30px;
}
/*------------------------------------------- bonus_container -------------------------------------------*/
.bonus_container .item {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.bonus_container .item:last-of-type .number:after {
  display: none;
}
.bonus_container .item .number {
  min-width: 130px;
  min-height: 130px;
  border: 2px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Abril Fatface';
  font-size: 60px;
  margin-right: 60px;
  position: relative;
}
.bonus_container .item .number:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 37px;
  width: 2px;
  background: #000;
}
.bonus_container .item .text {
  font-size: 28px;
  line-height: 31px;
  font-family: 'Athelas';
}
/*------------------------------------------- card_container -------------------------------------------*/
.card_container {
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
}
.card_container:before {
  content: "";
  display: block;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #f0f0f0;
  border-radius: 50%;
}
.card_container h4 {
  text-transform: uppercase;
}
.card_container p {
  font-size: 20px;
  font-family: 'Athelas';
}
.card_container .card_block {
  align-items: flex-end;
  position: relative;
  z-index: 10;
}
.card_container .card_block .card {
  text-align: center;
}
.card_container .card_block .scoring {
  margin-top: 100px;
}
.card_container .card_block .scoring p {
  padding: 10px 20px;
  background: #fff;
  display: inline-block;
  border-radius: 10px;
  margin-top: 20px;
}
.card_container .info_container {
  margin-top: 100px;
}
.card_container .info_container h4 {
  font-weight: bold;
}
.card_container .info_container .important {
  padding-left: 100px;
  position: relative;
  z-index: 10;
}
.card_container .info_container .important:before {
  content: "";
  display: inline-block;
  width: 69px;
  height: 69px;
  background-image: url(../img/important-icon.png);
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: -12px;
  left: 0;
}
.card_container .info_container .question {
  padding-left: 100px;
  position: relative;
  z-index: 10;
}
.card_container .info_container .question:before {
  content: "";
  display: inline-block;
  width: 69px;
  height: 69px;
  background-image: url(../img/question-icon.png);
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: -12px;
  left: 0;
}
.card_container .btn_container {
  position: relative;
  z-index: 10;
  margin-top: 100px;
  text-align: center;
}
/*------------------------------------------- certificate_container -------------------------------------------*/
.certificate_container p {
  font-size: 28px;
  line-height: 31px;
  font-family: 'Athelas';
  margin-bottom: 40px;
}
/*------------------------------------------- competition_container -------------------------------------------*/
.competition_container {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.competition_container h4 {
  font-size: 36px;
  line-height: 39px;
}
.competition_container .competition_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}
/*------------------------------------------- personal_area_container -------------------------------------------*/
.personal_area_container {
  margin-top: 250px;
}
.personal_area_container h2 {
  text-align: center;
  font-size: 40px;
}
.personal_area_container .personal_area {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.personal_area_container .personal_area .sidebar {
  width: 30%;
}
.personal_area_container .personal_area .sidebar .img_container {
  height: 400px;
  overflow: hidden;
}
.personal_area_container .personal_area .sidebar .btn_3 {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.personal_area_container .personal_area .sidebar .btn_2 {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}
.personal_area_container .personal_area .sidebar .caption_block {
  background: #bc916c;
  padding: 5px 10px;
  color: #fff;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.personal_area_container .personal_area .sidebar .icons_cobtainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.personal_area_container .personal_area .sidebar .icons_cobtainer .icon {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  margin-right: 10px;
}
.personal_area_container .personal_area .sidebar .competition .item {
  display: flex;
  margin-top: 15px;
}
.personal_area_container .personal_area .sidebar .competition .item .name {
  width: 70%;
}
.personal_area_container .personal_area .sidebar .competition .item .number {
  width: 30%;
  text-align: center;
}
.personal_area_container .personal_area .main {
  width: 65%;
}
.personal_area_container .personal_area .main h4 {
  font-size: 36px;
  line-height: 39px;
  text-transform: uppercase;
}
.personal_area_container .personal_area .main .personal_info {
  border-bottom: 2px solid #000;
  padding-bottom: 40px;
}
.personal_area_container .personal_area .main .personal_info .divider {
  color: #fff;
  background: #bc916c;
  padding: 20px 25px;
  margin-top: 40px;
  font-size: 24px;
  line-height: 27px;
}
.personal_area_container .personal_area .main .personal_info .personal_item {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.personal_area_container .personal_area .main .personal_info .personal_item p {
  width: 345px;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
}
.personal_area_container .personal_area .main .personal_info .personal_item span {
  font-size: 24px;
  line-height: 29px;
  font-family: 'Athelas';
}
.personal_area_container .personal_area .main .my_cards {
  margin-top: 40px;
}
.personal_area_container .personal_area .main .my_cards .card {
  margin-top: 30px;
}
.personal_area_container .personal_area .main .my_cards .card .text p {
  font-size: 36px;
  line-height: 39px;
  margin-bottom: 45px;
}
.personal_area_container .personal_area .main .my_cards .card .text p span {
  display: block;
  font-size: 20px;
  line-height: 23px;
}
.personal_area_container .personal_area .main .checkbox_container .personal_information_form {
  margin-top: 20px;
}
/*------------------------------------------- news_block -------------------------------------------*/
.news_block {
  margin-top: 100px;
  margin-bottom: 200px;
}
.news_block h2 {
  text-align: center;
  font-size: 40px;
  line-height: 43px;
}
.news_block .news {
  margin-top: 60px;
}
.news_block .news .news_item {
  overflow: hidden;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid #000;
  padding-bottom: 20px;
}
.news_block .news .news_item .img_container {
  height: 335px;
  overflow: hidden;
}
.news_block .news .news_item .img_container img {
  height: 100%;
  max-width: none;
}
.news_block .news .news_item h4 {
  margin-top: 30px;
  text-align: center;
}
.news_block .news .news_item .info {
  margin-top: auto;
  font-family: 'Athelas';
}
/*------------------------------------------- slider_container -------------------------------------------*/
.slider_container {
  margin-top: 40px;
}
.slider_container .main_slider img {
  width: 100%;
}
/*------------------------------------------- menu_container -------------------------------------------*/
.menu_container {
  margin-top: 160px;
}
.menu_container .menu {
  display: flex;
  flex-wrap: wrap;
}
.menu_container .menu li {
  width: 25%;
  margin-top: 20px;
}
.menu_container .menu li.active a {
  text-decoration: underline;
  color: #511e1e;
}
.menu_container .menu li a {
  font-size: 23px;
  line-height: 23px;
  text-transform: uppercase;
  color: #000;
}
/*------------------------------------------- all_menu -------------------------------------------*/
.all_menu h2 {
  text-align: center;
  margin: 50px 0 35px 0;
}
.all_menu .menu_block {
  display: flex;
  flex-wrap: wrap;
}
.all_menu .menu_block .item {
  width: 32%;
  margin-top: 22px;
  margin-right: 22px;
  display: flex;
  flex-direction: column;
}
.all_menu .menu_block .item:nth-of-type(3n) {
  margin-right: 0;
}
.all_menu .menu_block .item .img_container {
  height: 230px;
  overflow: hidden;
}
.all_menu .menu_block .item .img_container img {
  width: 100%;
}
.all_menu .menu_block .item p {
  margin-top: 25px;
  text-align: center;
  padding: 0 20px;
}
.all_menu .menu_block .item span {
  display: block;
  text-align: center;
  padding: 0 20px;
  font-size: 13px;
}
.all_menu .menu_block .item .price_block {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}
.all_menu .menu_block .item .price_block .price {
  font-size: 31px;
  line-height: 34px;
}
.all_menu .menu_block .item .price_block .btn_3 {
  padding: 5px 15px;
  font-size: 15px;
  width: 150px;
  text-align: center;
}
/*------------------------------------------- delivery_info -------------------------------------------*/
.delivery_info {
  margin: 100px 0 200px 0;
}
.delivery_info p {
  margin-top: 20px;
}
.delivery_info .red_color {
  color: #511e1e;
}
.delivery_info a {
  color: #000;
  text-decoration: underline;
}
/*------------------------------------------- fixed_block -------------------------------------------*/
.fixed_block {
  z-index: 100;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #21201e;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
}
.fixed_block .btn_3 {
  padding: 5px 25px;
  font-size: 15px;
}
.fixed_block .price {
  color: #fff;
}
/*------------------------------------------- basket_divider -------------------------------------------*/
.basket_divider {
  margin-top: 215px;
}
/*------------------------------------------- basket_container -------------------------------------------*/
.basket_container {
  margin-bottom: 200px;
}
.basket_container form .personal_information_form {
  width: 40px;
  height: 40px;
}
.basket_container form .personal_information_form span {
  position: static;
}
.basket_container .additional_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0 0 0;
}
.basket_container .additional_block input {
  width: 250px;
  margin-right: 15px;
}
.basket_container .additional_block .btn_2 {
  width: 250px;
  text-align: center;
  padding: 13px 20px;
  margin-right: 15px;
  font-size: 16px;
}
.basket_container .additional_block .btn_3 {
  width: 250px;
  text-align: center;
  padding: 13px 20px;
  font-size: 16px;
}
.basket_container .close {
  width: 70px;
  display: flex;
  align-items: center;
}
.basket_container .name {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.basket_container .name.tar {
  justify-content: flex-end;
}
.basket_container .number {
  width: 210px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.basket_container .number span {
  font-size: 26px;
  line-height: 29px;
  font-weight: bold;
  margin: 0 15px;
}
.basket_container .price {
  width: 150px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.basket_container .price span {
  font-size: 30px;
  line-height: 33px;
  font-weight: bold;
}
.basket_container .caption {
  border-bottom: 2px solid #bc916c;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.basket_container .order .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #bc916c;
  padding: 10px 0;
}
.basket_container .order .item .close_btn {
  width: 41px;
  height: 41px;
  background-image: url(../img/close.png);
  -webkit-background-size: cover;
  background-size: cover;
  cursor: pointer;
  display: inline-block;
}
.basket_container .order .item .img_container {
  width: 230px;
  height: 150px;
  overflow: hidden;
  margin-right: 20px;
}
.basket_container .order .item .text {
  font-size: 26px;
  line-height: 29px;
}
.basket_container .order .item .plus {
  width: 41px;
  height: 41px;
  background-image: url(../img/plus.png);
  -webkit-background-size: cover;
  background-size: cover;
  cursor: pointer;
}
.basket_container .order .item .minus {
  width: 43px;
  height: 41px;
  background-image: url(../img/minus.png);
  -webkit-background-size: cover;
  background-size: cover;
  cursor: pointer;
}
/*------------------------------------------- basket_registration_container -------------------------------------------*/
.basket_registration_container {
  margin-bottom: 200px;
}
.basket_registration_container .container {
  display: flex;
  justify-content: space-between;
}
.basket_registration_container .container form {
  width: 47%;
}
.basket_registration_container .container form h4 {
  color: #bc916c;
  font-size: 36px;
  line-height: 39px;
  margin-bottom: 30px;
  text-align: center;
}
.basket_registration_container .container form input {
  margin-top: 10px;
}
.basket_registration_container .reg_bloc {
  display: flex;
  flex-direction: column;
}
.basket_registration_container .reg_bloc .btn_2 {
  font-size: 20px;
  padding: 14px 20px;
}
.basket_registration_container .reg_bloc .btn_container_2 {
  margin-top: auto;
}
.basket_registration_container .reg_bloc .btn_container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.basket_registration_container .reg_bloc .btn_container button {
  width: auto;
  margin-right: 10px;
}
.basket_registration_container .reg_bloc .btn_container a {
  color: #000;
}
.basket_registration_container .quick_order .btn_container {
  margin-top: 30px;
  text-align: right;
}
.basket_registration_container .quick_order .btn_container button {
  width: auto;
}
.basket_registration_container .quick_order .item {
  display: flex;
  align-items: center;
}
.basket_registration_container .quick_order .item .title {
  min-width: 150px;
  margin-top: 10px;
}
/*------------------------------------------- photo_divider -------------------------------------------*/
.photo_divider {
  margin-top: 260px;
}
/*------------------------------------------- menu_container_mobile -------------------------------------------*/
.menu_container_mobile {
  display: none;
}
.menu_container_mobile .menu_item {
  display: block;
  position: relative;
  margin-bottom: 20px;
}
.menu_container_mobile .menu_item h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 300px;
  text-align: center;
}
/*------------------------------------------- mobile_only -------------------------------------------*/
.mobile_only {
  display: none;
}
/*------------------------------------------- photo_container_mobile -------------------------------------------*/
.photo_container_mobile {
  display: none;
  padding: 0 20px;
}
.photo_container_mobile .slick-prev {
  width: 38px;
  height: 38px;
  background-image: url(../img/left-arrow.png);
  z-index: 10;
  left: -15px;
}
.photo_container_mobile .slick-prev:before {
  display: none;
}
.photo_container_mobile .slick-next {
  width: 38px;
  height: 38px;
  background-image: url(../img/right-arrow.png);
  z-index: 10;
  right: -16px;
}
.photo_container_mobile .slick-next:before {
  display: none;
}
/*------------------------------------------- top -------------------------------------------*/
#top {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1500px) {
  .one_event .left_arrow {
    left: -40px;
  }
  .one_event .right_arrow {
    right: -40px;
  }
  header .authorization {
    left: auto;
    right: 17px;
    top: 79px;
    animation: none;
    display: flex;
    align-items: flex-start;
  }
  header .authorization a.in {
    margin-bottom: 0;
    margin-right: 10px;
  }
  header .authorization.log_out {
    top: 62px;
    right: 0;
  }
  header .nav.header_left .top .btn_2 {
    margin-right: 10px;
  }
  header .nav.header_left .top {
    justify-content: flex-start;
  }
  header .nav.header_right {
    width: 39%;
  }
  header .nav.header_left {
    width: 39%;
  }
  header .nav.header_left .bottom p {
    font-size: 15px;
  }
  header .nav.header_left .top .caption {
    font-size: 15px;
  }
  header .nav.header_right .bottom p {
    font-size: 15px;
  }
  header .logo {
    margin: 0 20px;
  }
  header .nav.header_right .top .btn_2 {
    margin-right: 5px;
  }
}
@media screen and (max-width: 1200px) {
  .all_menu .menu_block .item {
    width: 31%;
  }
  .menu_container .menu li a {
    font-size: 18px;
  }
  .personal_area_container .personal_area .sidebar .btn_2 {
    font-size: 13px;
  }
  .look_block .item .text {
    font-size: 16px;
  }
  .main_banner.one_restaurant .right_bg .white_bg {
    padding: 30px;
    margin-left: 45px;
    width: 370px;
  }
  .stages_container .step_by_step .step:nth-of-type(7):after {
    left: 112%;
  }
  .stages_container .step_by_step .step:nth-of-type(2):before {
    top: -47px;
    left: 84px;
  }
  .additional_info .grey_border .item {
    width: 37%;
  }
  .advantages_container .container .advantages_item {
    width: 300px;
    height: 300px;
  }
  .advantages_container .container .advantages_item h4 {
    font-size: 20px;
  }
  header .nav {
    width: 330px;
  }
  header .nav li a {
    font-size: 13px;
  }
  .review_container .give_feedback {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .review_container .give_feedback .btn_3 {
    margin-top: 20px;
  }
  .review_container .review_block .review_item .left {
    width: 70%;
  }
  .review_container .review_block .review_item .right {
    width: 30%;
  }
  .review_container .give_feedback p {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 992px) {
  .slider_container {
    margin-top: 130px;
  }
  .basket_registration_container .reg_bloc .btn_container_2 {
    margin-top: 20px;
  }
  .basket_registration_container .reg_bloc .btn_2 {
    width: 100%;
    text-align: center;
  }
  .mobile_no {
    display: none !important;
  }
  .basket_container .order .item .close_btn {
    width: 30px;
    height: 30px;
  }
  header .authorization a {
    border-radius: 0;
  }
  .delivery_info {
    margin: 50px 0 100px 0;
  }
  .all_menu .menu_block .item {
    width: 100%;
    margin-right: 0;
  }
  .slider_container .main_slider .slick-dots li {
    display: inline-block;
    margin: 10px;
    border: 1px solid #000;
  }
  .slider_container .main_slider .slick-dots {
    width: 100%;
    transform: translateY();
    left: 0;
    right: 0;
    top: auto;
    bottom: -60px;
  }
  .slider_container .main_slider .slick-slide .text h2 {
    font-size: 30px;
    line-height: 33px;
  }
  .slider_container .main_slider .slick-slide .img {
    width: 100%;
  }
  .slider_container .main_slider .slick-slide .text {
    width: 100%;
  }
  .slider_container .main_slider .slick-slide {
    display: block;
    height: 500px;
  }
  .slider_container .slider_preview {
    display: none;
  }
  .menu_container {
    display: none;
  }
  header .big_container {
    position: static;
  }
  header .nav.header_right {
    display: none;
  }
  header .nav.header_left {
    display: none;
  }
  .competition_container {
    margin: 50px 0 100px 0;
  }
  .competition_container .competition_block > div {
    margin-top: 30px;
  }
  .competition_container .competition_block {
    display: block;
  }
  .card_container .info_container .question {
    width: 100%;
    margin-top: 30px;
  }
  .card_container .info_container .important {
    width: 100%;
  }
  .card_container .info_container {
    display: block;
  }
  .card_container .card_block .card {
    margin-top: 50px;
  }
  .card_container .card_block .scoring {
    display: none;
  }
  .card_container .card_block {
    display: block;
  }
  .card_container:before {
    display: none;
  }
  .main_banner.personal_area .login {
    width: 100%;
    padding: 30px;
  }
  .main_banner.personal_area {
    padding-top: 145px;
    padding-bottom: 50px;
  }
  .look_block .item .text .sub_text {
    padding: 0 10px;
    text-align: center;
  }
  .look_block .item .text .sub_text:after {
    display: none;
  }
  .one_human_container .one_human .white_bg {
    width: 90%;
  }
  .one_human_container .one_human .white_bg:before {
    display: none;
  }
  .one_human_container {
    margin-top: 100px;
    padding-bottom: 100px;
  }
  .review_btns .btn_3 {
    margin-bottom: 30px;
  }
  .review_btns {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .restaurant_review {
    width: 100%;
  }
  .new_event .item {
    margin-top: 50px;
  }
  .main_banner.one_restaurant .right_bg .white_bg {
    width: 90%;
    margin: 0 auto;
  }
  .main_banner.one_restaurant .right_bg .white_bg:before {
    display: none;
  }
  .main_banner.one_restaurant .right_bg {
    width: 100%;
    padding: 150px 0 50px 0;
  }
  .main_banner.one_restaurant .left_bg {
    display: none;
  }
  .main_banner.one_restaurant {
    display: block;
    height: auto;
  }
  .stages_container .step_by_step {
    display: none;
  }
  .stages_container .white_bg {
    padding: 20px;
    width: 100%;
  }
  .stages_container .stages_block {
    display: block;
  }
  .stages_container {
    padding: 50px 0;
  }
  .additional_info .grey_border .item {
    width: 100%;
    margin: 30px 0 0 0;
  }
  .additional_info .grey_border {
    padding: 0 30px 30px 30px;
  }
  .additional_info .grey_border .item img {
    height: auto;
  }
  .additional_info .bg_block {
    display: none;
  }
  .conditions_container .conditions_item h4 {
    width: 100%;
  }
  .conditions_container .conditions_item {
    display: block;
  }
  .pride {
    padding: 70px 0 70px 0;
  }
  .advantages_container .container .advantages_item h4 {
    font-size: 18px;
    line-height: 18px;
  }
  .advantages_container .container .advantages_item .number {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 5px;
  }
  .advantages_container .container .advantages_item {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
  .advantages_container .container {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -420px;
  }
  .advantages_container .container .advantages_item {
    position: static !important;
    transform: translate(0, 0) !important;
  }
  .advantages_container {
    background-image: none;
    background: #f2f2f2;
  }
  .form_communication .form .brown_border .tel {
    font-size: 20px;
  }
  .form_communication .form .brown_border .mail {
    font-size: 20px;
  }
  .form_communication .form .brown_border {
    border: none;
    padding: 0;
  }
  .form_communication .form h3 {
    font-size: 40px;
  }
  .form_communication .form {
    padding: 30px;
  }
  .review_container .review_block .review_item .left {
    width: 60%;
  }
  .review_container .review_block .review_item .right {
    width: 40%;
  }
  .video_container .video_item {
    height: auto;
    margin-bottom: 0;
  }
  .photo_container .photo_item {
    height: auto;
    margin-bottom: 0;
  }
  .stock_2 {
    background-image: url(../img/stock_2.png);
    -webkit-background-size: cover;
    background-size: cover;
    padding: 60px 15px;
    margin-top: 0;
  }
  .stock_2 .stock_2_container {
    position: static;
  }
  .stock_2 img {
    display: none;
  }
  .menu_container .menu_item {
    margin-bottom: 30px;
  }
  .flex_4_column > a {
    width: 49%;
  }
  .flex_4_column > div {
    width: 49%;
  }
  .main_banner.main h1 {
    font-size: 50px;
    line-height: 63px;
  }
  .main_banner.main {
    margin-top: 100px;
    padding: 50px 0 30px 0;
  }
  .main_banner.main h4 {
    font-size: 16px;
    line-height: 19px;
    margin-top: 30px;
  }
  header.white .nav {
    display: none;
  }
  header .nav {
    display: none;
  }
  header .authorization {
    position: static;
  }
  header .container {
    position: static;
  }
  .nav_container {
    display: block;
  }
  header {
    height: 100px;
  }
  header .authorization a.reg {
    display: none;
  }
  header .authorization a.in_mobile {
    display: inline-block;
  }
  header .authorization a.in {
    display: none;
  }
  header .logo {
    width: 185px;
  }
  h1 {
    font-size: 50px;
  }
  .main_banner.developments {
    padding-top: 190px;
    padding-bottom: 90px;
  }
  .divider_container .icon {
    display: none;
  }
  .divider_container .container {
    justify-content: center;
  }
  .divider_container {
    margin: 0 0 50px 0;
  }
  .divider_container .text {
    padding-top: 50px;
  }
  h2 {
    font-size: 40px;
  }
  h4 {
    font-size: 25px;
  }
  .filter_container .address_block {
    flex-direction: column;
  }
  .filter_container .address_block h4 {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .filter_container .address_block .date {
    margin-left: 0;
    margin-top: 50px;
  }
  .filter_container .calendar_block li {
    display: none;
    min-width: 100px;
    height: 100px;
    background: #21201e;
    border-radius: 50%;
    margin-bottom: 20px;
    font-size: 25px;
  }
  .filter_container .calendar_block li.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .filter_container .calendar_block {
    justify-content: center;
    margin-top: 30px;
  }
  .filter_container .calendar_block li .day {
    margin-bottom: 0;
  }
  .filter_container .calendar_block:after {
    bottom: 60px;
  }
  .filter_container .calendar_block:before {
    bottom: 60px;
  }
  .filter_container .filter_mobile {
    display: block;
  }
  .filter_container .filter_btn_block {
    margin-top: 20px;
    display: none;
  }
  .flex_3_column > div {
    width: 49%;
  }
  footer .nav {
    display: block;
    text-align: center;
  }
  footer .nav li {
    margin-bottom: 22px;
  }
  footer .bottom_block {
    flex-direction: column;
    margin-top: 50px;
  }
  footer .bottom_block .social a {
    margin: 0 10px;
  }
  footer .bottom_block .agreement {
    margin-top: 22px;
    margin-left: 0;
  }
  footer .bottom_block p {
    margin-top: 22px;
  }
  footer .bottom_block .social {
    margin-top: 22px;
  }
  footer .bottom_block .social a img {
    width: 50px;
  }
  footer .bottom_block .language a {
    width: 50px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
  }
  .flex_3_column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .flex_3_column > div {
    width: 49%;
  }
  .flex_3_column > a {
    width: 49%;
  }
  .report_container.video .report_item .img_block {
    height: auto;
  }
  .look_block .item {
    margin-top: 20px;
  }
  .main_banner.restaurant {
    margin-top: 100px;
  }
  .map_filter .review {
    text-align: left;
    margin-top: 20px;
  }
  .registration_container .registration_block .item {
    display: block;
  }
  .registration_container .registration_block .item .caption {
    width: 100%;
  }
  .registration_container .registration_block .item .field {
    width: 100%;
    margin-top: 10px;
  }
  .registration_container .registration_block .item .field.flex_block {
    display: block;
  }
  .registration_container .registration_block .item .field.flex_block > div {
    margin-right: 0;
    margin-top: 10px;
  }
  .registration_container {
    margin-bottom: 100px;
  }
  .main_banner.registration h1 {
    font-size: 37px;
  }
  .password_container {
    margin: 50px 0 100px 0;
  }
  .password_container .password_flex {
    display: block;
  }
  .password_container .password_flex > div {
    margin: 20px 0 0 0;
  }
  header .authorization.log_out a.in_mobile {
    background-image: url(../img/logout.png);
  }
  .personal_area_container h2 {
    font-size: 30px;
  }
  .personal_area_container .personal_area {
    display: block;
  }
  .personal_area_container .personal_area .sidebar {
    width: 100%;
  }
  .personal_area_container .personal_area .main {
    width: 100%;
    margin-top: 40px;
  }
  .personal_area_container .personal_area .main h4 {
    font-size: 30px;
  }
  .personal_area_container .personal_area .main .personal_info .personal_item {
    margin-top: 20px;
  }
  .news_block .news .news_item {
    margin-top: 30px;
  }
  .news_block {
    margin-bottom: 100px;
  }
  .fixed_block {
    display: flex;
  }
  .basket_divider {
    margin: 100px 0 50px 0;
  }
  .basket_container .order .item {
    display: block;
  }
  .basket_container .caption .close {
    display: none;
  }
  .basket_container .caption .name {
    display: none;
  }
  .basket_container .caption .number {
    display: none;
  }
  .basket_container .caption .price {
    display: none;
  }
  .basket_container .caption {
    padding: 0;
  }
  .basket_container .name {
    display: block;
  }
  .basket_container .close {
    width: auto;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .basket_container .order .item .img_container {
    width: 100%;
    height: auto;
  }
  .basket_container .order .item .img_container img {
    width: 100%;
  }
  .basket_container .order .item .text {
    text-align: center;
    margin: 20px 0;
  }
  .basket_container .number {
    width: 100%;
  }
  .basket_container .price {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
  .basket_container .additional_block {
    display: block;
  }
  .basket_container .additional_block .number {
    margin-top: 40px;
  }
  .basket_container .additional_block input {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
  .basket_container .additional_block .btn_2 {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
  .basket_container .additional_block .btn_3 {
    width: 100%;
    margin-top: 20px;
  }
  .basket_container {
    margin-bottom: 100px;
  }
  .basket_registration_container .container {
    display: block;
  }
  .basket_registration_container .container form {
    width: 100%;
  }
  .basket_registration_container .container form h4 {
    margin-bottom: 10px;
  }
  .basket_registration_container .reg_bloc .btn_container {
    display: block;
    margin-top: 10px;
  }
  .basket_registration_container .reg_bloc .btn_container button {
    width: 100%;
    margin-right: 0;
  }
  .basket_registration_container .reg_bloc .btn_container a {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  .basket_registration_container .container form.quick_order {
    margin-top: 50px;
  }
  .basket_registration_container .quick_order .item {
    display: block;
  }
  .basket_registration_container .quick_order .btn_container button {
    width: 100%;
  }
  .basket_registration_container .quick_order .btn_container {
    margin-top: 10px;
  }
  .basket_registration_container {
    margin-bottom: 100px;
  }
  .divider_container.photo_divider {
    margin: 100px 0 50px 0;
  }
  .personal_area_container {
    margin-top: 160px;
  }
  h1 span {
    display: block;
    font-size: 74px;
  }
  .main_menu_container {
    display: none;
  }
  .container.full_width {
    padding: 0;
  }
  .stock_1 {
    height: auto;
    display: block;
  }
  .stock_1 .bg_img {
    position: static;
  }
  .stock_1 .dark_bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px;
  }
  .stock_1 .dark_bg p {
    font-size: 12px;
    line-height: 14px;
    margin-top: 0;
  }
  .btn_1 {
    font-size: 17px;
  }
  .video_container {
    margin-bottom: 80px;
  }
  .mobile_only {
    display: block;
  }
  .menu_container_mobile {
    display: block;
  }
  .stock_2 .stock_2_container .divider_container {
    display: none;
  }
  .stock_2 .stock_2_container .btn_1 {
    margin-top: 30px;
  }
  .photo_container .photo_item:before {
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
  }
  .video_container .video_item:after {
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
  }
  .photo_container .flex_2_column {
    display: none;
  }
  .video_container .photo_item:before {
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
  }
  .video_container .flex_2_column {
    display: none;
  }
  .photo_container_mobile {
    display: block;
  }
  footer .container {
    display: flex;
    justify-content: space-between;
  }
  footer .nav {
    text-align: left;
    width: 49%;
  }
  footer .bottom_block {
    margin-top: 0;
    width: 49%;
    align-items: flex-end;
  }
  footer .bottom_block .agreement {
    text-align: right;
  }
  .main_banner.restaurant .select {
    margin-top: 55px;
  }
  .main_banner.restaurant h1 span {
    font-size: 74px;
  }
  .main_banner.restaurant h1 {
    font-size: 50px;
    line-height: 63px;
  }
  .divider_container.main_divider .text h3 {
    color: #a32135;
  }
  .divider_container.main_divider {
    margin: 0 0 5px 0;
  }
  .divider_container.main_divider .text {
    position: relative;
    padding-top: 30px;
  }
  .divider_container.main_divider .text:before {
    content: "";
    height: 22px;
    width: 352px;
    position: absolute;
    top: 60px;
    right: 110%;
    background-image: url(../img/divider.png);
    display: inline-block;
  }
  .divider_container.main_divider .text:after {
    content: "";
    height: 22px;
    width: 352px;
    position: absolute;
    top: 60px;
    left: 110%;
    background-image: url(../img/divider.png);
    display: inline-block;
  }
  .basket_registration_container .container form input {
    padding: 0 0 0 15px;
  }
}
@media screen and (max-width: 768px) {
  .all_menu .menu_block .item .price_block {
    padding: 20px 5px;
  }
  .slider_container .main_slider .slick-slide {
    height: auto;
  }
  .slider_container .main_slider .slick-slide .img {
    display: none;
  }
  .news_block .news {
    margin-top: 0;
  }
  .news_block {
    margin-top: 50px;
  }
  .personal_area_container .personal_area .sidebar .caption_block {
    font-size: 16px;
  }
  .personal_area_container {
    margin-top: 50px;
  }
  .personal_area_container .personal_area .main .personal_info .personal_item p {
    width: 100%;
  }
  .personal_area_container .personal_area .main .personal_info .personal_item {
    display: block;
  }
  .certificate_container {
    text-align: center;
  }
  .certificate_container .btn_3 {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .certificate_container p {
    text-align: center;
    margin-bottom: 20px;
  }
  .card_container .btn_4 {
    font-size: 12px;
  }
  .card_container {
    padding-top: 50px;
  }
  .bonus_container .item .text {
    text-align: center;
  }
  .bonus_container .item .number:after {
    display: none;
  }
  .bonus_container .item .number {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  .main_banner.personal_area .login .btn_4 {
    font-size: 12px;
  }
  .bonus_container .item {
    display: block;
  }
  .review_container .btn_container {
    padding-bottom: 100px;
  }
  .review_container .review_block .review_item .right .stars {
    justify-content: flex-start;
    margin-top: 20px;
  }
  .review_container .review_block .review_item .right .date {
    text-align: left;
  }
  .review_container .review_block .review_item .right {
    width: 100%;
    margin-top: 20px;
  }
  .review_container .review_block .review_item .left {
    width: 100%;
  }
  .review_container .review_block .review_item {
    padding: 50px 20px;
    display: block;
  }
  .main_banner.review {
    padding-top: 165px;
    padding-bottom: 50px;
  }
  .place_container {
    margin-bottom: 100px;
  }
  .place_container .place_item .img_container img {
    height: auto;
    width: 100%;
  }
  .place_container .place_item .img_container {
    height: auto;
  }
  .filter_container .text_block {
    margin: 50px 0;
  }
  .main_banner.restaurant {
    padding: 50px 0;
  }
  .map_filter {
    margin: 50px 0;
  }
  .map_filter .select {
    margin-top: 20px;
  }
  .about_description {
    margin-top: 170px;
  }
  .franchise_container .franchise_block .franchise_item {
    margin-top: 40px;
  }
  .partner_container {
    flex-wrap: wrap;
  }
  .partner_container > div {
    width: 49%;
    text-align: center;
  }
  .rewards_container {
    padding: 50px 0;
  }
  .look_block .item {
    height: 300px;
  }
  .number_block .white_bg .numbers .number_item .num {
    font-size: 60px;
    line-height: 60px;
  }
  .number_block .white_bg .numbers {
    justify-content: center;
    flex-wrap: wrap;
  }
  .number_block .white_bg .numbers .number_item {
    width: 40%;
    margin: 20px 10px 0 10px;
  }
  .number_block .white_bg .divider {
    justify-content: center;
  }
  .number_block .white_bg .divider .icon {
    display: none;
  }
  .one_event {
    padding-bottom: 100px;
  }
  .one_event .img_block {
    height: auto;
  }
  .one_event:before {
    display: none;
  }
  .one_event .left_arrow {
    left: 20px;
  }
  .one_event .right_arrow {
    right: 20px;
  }
  .description_block_2 {
    margin-bottom: 100px;
  }
  .one_video iframe {
    height: 250px;
  }
  .one_video:after {
    display: none;
  }
  .gallery .gallery_item img {
    width: 100%;
  }
  .pagination {
    margin-bottom: 100px;
  }
  .report_container .report_item .img_block {
    height: auto;
  }
  .flex_3_column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .flex_3_column > div {
    width: 100%;
  }
  .flex_3_column > a {
    width: 100%;
  }
  .flex_3_column > div {
    width: 100%;
  }
  .developments_container .developments_item .img_container {
    height: auto;
  }
  .developments_container .developments_item .img_container img {
    height: auto;
    width: 100%;
  }
  .menu_container .menu_item {
    margin-bottom: 70px;
  }
  .look_block {
    margin: 50px 0;
  }
  .main_banner.contacts {
    padding-bottom: 490px;
  }
  .all_contacts form {
    padding: 30px;
  }
  .all_contacts form .submit_block > div {
    width: 100%;
  }
  .all_contacts form .submit_block button {
    margin-bottom: 20px;
  }
  .all_contacts form input {
    margin-top: 20px;
  }
  .all_contacts .contacts .contact_item {
    margin-top: 60px;
  }
}
@media screen and (max-width: 480px) {
  .form_communication .form .brown_border {
    margin-top: 0;
  }
  .receipts {
    margin: 50px 0 100px 0;
  }
  .receipts h2 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .our_advantages .item h4 {
    padding-left: 0;
  }
  .advantages_container .container {
    justify-content: center;
  }
  .form_communication .form .brown_border .tel {
    font-size: 12px;
  }
  .form_communication .form .brown_border .mail {
    font-size: 12px;
  }
  .select .text_menu {
    width: 270px;
  }
  .filter_container .filter_mobile {
    width: 270px;
  }
  .more_btn .more {
    padding: 20px;
    font-size: 14px;
  }
  .number_block .white_bg .numbers .number_item {
    width: 100%;
  }
}
@media screen and (max-width: 374px) {
  .flex_4_column > a {
    width: 100%;
  }
  .flex_4_column > div {
    width: 100%;
  }
  header .logo {
    width: 150px;
  }
  header.right {
    transform: translateX(261px);
  }
  .flex_2_column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .flex_2_column > div {
    width: 100%;
  }
  .flex_2_column > a {
    width: 100%;
  }
}
